import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PublicRoute from './router/PublicRoute';

// Keep frequently used or small components as regular imports
import Layout from './components/login/Layout';
import ProfileLayout from './components/user-profile/ProfileLayout';
import LoadingScreen from './common/LoadingScreen';
import SingleProduct from './components/product/SingleProduct';

// const SingleProduct = lazy(() => import('./components/product/SingleProduct'));

// Lazy load route components
const Home = lazy(() => import('./components/home/Home'));
const UserData = lazy(() => import('./components/user-profile/sections/UserData'));
const Addresses = lazy(() => import('./components/user-profile/sections/Addresses/Addresses'));
const AddNewAddress = lazy(() => import('./components/user-profile/sections/Addresses/AddNewAddress'));
const Orders = lazy(() => import('./components/user-profile/sections/orders/Orders'));
const Order = lazy(() => import('./components/user-profile/sections/orders/Order'));
const HelpAndContact = lazy(() => import('./components/user-profile/sections/HelpAndContact/HelpAndContact'));
const AddPaymentMethod = lazy(() => import('./components/user-profile/sections/PaymentMethods/AddPaymentMethod'));
const PaymentMethods = lazy(() => import('./components/user-profile/sections/PaymentMethods/PaymentMethods'));
const EditPaymentMethod = lazy(() => import('./components/user-profile/sections/PaymentMethods/EditPaymentMethod'));
const CountryScreen = lazy(() => import('./components/login/CountryScreen'));
const DraftPage = lazy(() => import('./components/draft/DraftPage'));
const CartHome = lazy(() => import('./components/cart/CartHome'));
const CartSuccess = lazy(() => import('./components/cart/CartSuccess'));
const ProductsPage = lazy(() => import('./components/products/ProductsPage'));
// const SingleProduct = lazy(() => import('./components/product/SingleProduct'));
const PaymentsInfo = lazy(() => import('./components/aboutInfo/PaymentsInfo'));
const TermsOfUse = lazy(() => import('./components/aboutInfo/TermsOfUse'));
const AboutUs = lazy(() => import('./components/aboutInfo/AboutUs'));
const Contact = lazy(() => import('./components/aboutInfo/Contact'));
const Refund = lazy(() => import('./components/aboutInfo/Refund'));
const PrivacyPolicy = lazy(() => import('./components/aboutInfo/PrivacyPolicy'));
const LoginScreen = lazy(() => import('./components/login/login-screens/LoginScreen'));
const ResetPasswordScreen = lazy(() => import('./components/login/login-screens/ResetPasswordScreen'));
const ForgotPasswordScreen = lazy(() => import('./components/login/login-screens/ForgotPasswordScreen'));
const RegistrationScreen = lazy(() => import('./components/login/login-screens/RegistrationScreen'));
const NewPasswordScreen = lazy(() => import('./components/login/login-screens/NewPasswordScreen'));

function AppRouter() {
	return (
		<Suspense fallback={<LoadingScreen visible />}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="profile/" element={<ProfileLayout />}>
						<Route path="user" element={<UserData />} />
						<Route path="addresses" element={<Addresses />} />
						<Route path="address" element={<AddNewAddress />} />
						<Route path="orders" element={<Orders />} />
						<Route path="order/:selectedOrderId" element={<Order />} />
						<Route path="help" element={<HelpAndContact />} />
						<Route path="paymentmethods" element={<PaymentMethods />} />
						<Route path="newpaymentmethod" element={<AddPaymentMethod />} />
						<Route path="editpaymentmethod" element={<EditPaymentMethod />} />
					</Route>
					<Route path="" element={<Home />} />

					<Route path="login" element={<PublicRoute Component={LoginScreen} />} />
					<Route path="reset-password" element={<PublicRoute Component={ResetPasswordScreen} />} />
					<Route path="forgot-password" element={<PublicRoute Component={ForgotPasswordScreen} />} />
					<Route path="register" element={<PublicRoute Component={RegistrationScreen} />} />
					<Route path="new-password" element={<PublicRoute Component={NewPasswordScreen} />} />

					<Route index path="country" element={<CountryScreen />} />
					<Route index path="drafts" element={<DraftPage />} />
					<Route index path="cart" element={<CartHome />} />
					<Route index path="cart/checkout" element={<CartHome />} />
					<Route index path="payment/success" element={<CartSuccess />} />
					<Route index path="products/:category_name" element={<ProductsPage />} />
					<Route index path="products/:category_name/:subcategory_name" element={<ProductsPage />} />
					<Route index path="product/:productName" element={<SingleProduct />} />
					<Route index path="placanje_i_isporuka" element={<PaymentsInfo />} />
					<Route index path="uslovi_koriscenja" element={<TermsOfUse />} />
					<Route index path="o_nama" element={<AboutUs />} />
					<Route index path="kontakt" element={<Contact />} />
					<Route index path="politika_privatnosti" element={<PrivacyPolicy />} />
					<Route index path="povracaj_sredstava" element={<Refund />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Route>
			</Routes>
		</Suspense>
	);
}

export default AppRouter;
