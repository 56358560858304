import { useEffect, useState } from 'react';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { checkIfUserFromSerbia, getAllFonts, getImage, getMenuCategories, getShippingZones, getZoneById, selectZoneAsync } from './server/server';
import { createGlobalStyle } from 'styled-components';
import { useMenuItems } from './hooks/useMenuItems';
import LoadingScreen from './common/LoadingScreen';
import { HelmetProvider } from 'react-helmet-async';
import AppRouter from './AppRouter';
import { GlobalDebug } from './util/remove-console';
import { useAppContext } from './hooks/useAppContext';

function App() {
	const [fonts, setFonts] = useState([]);
	const { setMenuItems } = useMenuItems();
	const { setZoneList, zone, selectZone } = useAppContext();
	const [initLoading, setInitLoading] = useState(true);

	useEffect(() => {
		loadInit();
	}, [zone]);

	const loadInit = async () => {
		await Promise.all([loadMenuItems(), loadFonts(), loadZones()]);
		setTimeout(() => {
			setInitLoading(false);
		}, 200);
	};

	const loadMenuItems = async () => {
		const resp = await getMenuCategories();
		if (resp.status === 200) {
			setMenuItems(resp.data);
		}
	};

	const loadZones = async () => {
		const resp = await getShippingZones();
		if (resp.status === 200) {
			setZoneList(resp.data);
			if (!zone) {
				const isSerbia = await checkIfUserFromSerbia();
				const zone = resp.data.find((z: any) => z.language.locale === (isSerbia ? 'sr' : 'en'));
				await submitZone(zone);
			} else {
			}
		}
	};

	const submitZone = async (zone: any) => {
		const resp = await selectZoneAsync({ id: zone.id });
		if (resp.status === 201) {
			const { accessToken } = resp.data;
			localStorage.setItem('accessToken', accessToken);
			await selectZone(zone);
		}
	};

	const loadFonts = async () => {
		const resp = await getAllFonts();
		if (resp.status === 200) {
			setFonts(resp.data);
		} else {
			console.log('fonts are not loaded.');
		}
	};

	const GlobalStyle = createGlobalStyle`
		body { ${fonts.map(
			(font: any) =>
				`@font-face {
						font-family: ${font.name};
						src: url(${getImage(font.imageId)}) format('truetype');
						font-style: normal;
						font-weight: 500;
						font-display: swap;
					}`
		)} }`;
	const helmetContext = {};

	useEffect(() => {
		(process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false, true);
	}, []);
	return (
		<div className="relative">
			<HelmetProvider context={helmetContext}>
				<LoadingScreen visible={initLoading} />
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<GlobalStyle />
					<AppRouter />
					<Toaster
						position="top-right"
						reverseOrder={false}
						containerStyle={{
							top: 125,
						}}
					/>
				</LocalizationProvider>
			</HelmetProvider>
			<div className="">
				{fonts.map((font: any) => (
					<span className="opacity-0 absolute z-0 overflow-hidden w-1" style={{ fontFamily: font.name, position: 'absolute', bottom: 0 }}>
						Petar - {font.name} - Obradovic
					</span>
				))}
			</div>
		</div>
	);
}

export default App;
