import React from 'react';
import { ReactComponent as BackIcon } from '../../../assets/icons/backIcon.svg';
import { useTranslation } from 'react-i18next';

function BackButton({ goBack }: { goBack: () => void }) {
	const { t } = useTranslation();
	return (
		<button className="absolute bg-[#FFFFFFA6] min-w-[92px] h-[44px] flex flex-row items-center justify-center gap-[10px] rounded-full top-[40px] left-[30px] z-[2] px-[10px]" onClick={goBack}>
			<BackIcon />
			<span className="font-semibold text-[16px]">{t('Back')}</span>
		</button>
	);
}

export default BackButton;
