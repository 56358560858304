import React from 'react';
import { ReactComponent as BackButton } from '../../../assets/icons/backConfigurationIcon.svg';
import { useTranslation } from 'react-i18next';

type Type = {
	onButtonClick?: () => void;
	onBack?: () => void;
};

function EditTextTopRowButtons({ onBack, onButtonClick }: Type) {
	const { t } = useTranslation();
	return (
		<div className="h-[74px] flex flex-row justify-between pt-[12px] pb-[22px] items-center border-b border-b-[#D4C4F6]">
			<button className="h-[21.67px] w-[21.67px] rounded-full bg-[#D1D5DB] ml-[11px] flex items-center justify-center" onClick={onBack}>
				<BackButton />
			</button>
			<button className="h-[40px] rounded-full w-[260px] flex items-center justify-center bg-[#E5DDF6] uppercase text-[14px] font-bold" onClick={onButtonClick}>
				{t('add new text box')}
			</button>
		</div>
	);
}

export default EditTextTopRowButtons;
