import React, { useEffect, useState } from 'react';
import { ReactComponent as LaykaStar } from '../../assets/icons/laykaStar.svg';
import WriteSomethingSection from './WriteSomethingSection';
import AddSymbolSection from './AddSymbolSection';
import ChooseDesignSection from './ChooseDesignSection';
import MakeYourOwn from './MakeYourOwn';
import DeleteCurrentDesignModal from './DeleteCurrentDesignModal';
import { useTranslation } from 'react-i18next';

interface NormalSideBarType {
	productId?: string;
	selectedObject?: any;
	loading?: boolean;
	canvas?: any;
	renderAll: () => void;
	addDesign?: ({ design }: { design: any }) => void;
	functions: {
		onAddText?: ({ text, fontFamily }: { text?: string; fontFamily?: string }) => void;
		fitText?: (obj: any) => void;
		addSymbol?: ({ symbol }: { symbol: string }) => void;
		addPhoto: (id: string) => void;
		alignText?: (type: string) => void;
		removeObject: (object?: any) => void;
		removeSelectedObject?: () => void;
		setDisabledButton: (value: boolean) => void;
	};
	chosenDesign?: any;
	resetChosenDesign?: () => void;
	updateTextVersion: any;
	engravingColor: string | null;
	saveDraft: any;
	disabledButton?: boolean;
	allowedCustomization: {
		text: boolean;
		image: boolean;
		symbol: boolean;
		allowPersonalization: boolean;
	};
	isAddPackageButtonVisible: boolean;
	product?: any;
}

const NormalSideBar = ({
	productId,
	canvas,
	functions,
	selectedObject,
	loading,
	renderAll,
	addDesign = () => {},
	chosenDesign,
	resetChosenDesign = () => {},
	updateTextVersion,
	engravingColor,
	saveDraft,
	disabledButton,
	allowedCustomization,
	isAddPackageButtonVisible,
	product,
}: NormalSideBarType) => {
	const { t } = useTranslation();
	const { onAddText, addSymbol, fitText, removeObject, setDisabledButton, addPhoto } = functions;
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [callbackFunction, setCallbackFunction] = useState<() => void>(() => {});
	const [tab, setType] = useState(!!chosenDesign ? 'choose' : 'makeOwn');

	useEffect(() => {
		if (!!chosenDesign) setType('choose');
	}, [chosenDesign]);

	useEffect(() => {
		if (!loading) {
			setType(!!chosenDesign || !allowedCustomization?.allowPersonalization ? 'choose' : 'makeOwn');
		}
	}, [loading]);

	const openMakeOwnTab = () => {
		if (!!chosenDesign) {
			setShowDeleteModal(true);
			setCallbackFunction(() => () => {
				setType('makeOwn');
				resetChosenDesign();
			});
		} else {
			setType('makeOwn');
		}
	};

	const addSelectedDesign = ({ design }: { design: any }) => {
		if (!disabledButton) {
			setShowDeleteModal(true);
			setCallbackFunction(() => () => addDesign({ design }));
		} else {
			addDesign({ design });
		}
	};

	const onConfirmResetDesign = () => {
		setShowDeleteModal(false);
		callbackFunction();
	};

	const tryToRemoveDesign = () => {
		setShowDeleteModal(true);
		setCallbackFunction(() => () => {
			resetChosenDesign();
		});
	};

	const onCancel = () => {
		setShowDeleteModal(false);
		setCallbackFunction(() => () => {});
	};

	const onSaveDraft = () => {
		saveDraft();
		setShowDeleteModal(false);
		resetChosenDesign();
	};

	return (
		<div className="flex flex-col flex-1">
			<div className="border-b-[#21187F] border-b border-solid flex flex-row">
				{allowedCustomization.allowPersonalization && (
					<button className={`default-config-tab ${tab === 'makeOwn' ? 'selected' : ''}`} onClick={openMakeOwnTab} disabled={!product?.hasDesigns}>
						<LaykaStar />
						<span>{t('Make your own')}</span>
					</button>
				)}
				{!!product?.hasDesigns && (
					<button className={`default-config-tab ${tab === 'choose' ? 'selected' : ''}`} onClick={() => setType('choose')}>
						<span>{t('Choose design')}</span>
					</button>
				)}
			</div>
			<div className={`flex flex-col flex-shrink overflow-y-scroll overflow-x-hidden ${isAddPackageButtonVisible ? 'max-h-[490px]' : 'max-h-[540px]'}`}>
				{tab === 'makeOwn' && (
					<MakeYourOwn
						addText={onAddText}
						selectedObject={selectedObject}
						canvas={canvas}
						renderAll={renderAll}
						fitText={fitText}
						productId={productId}
						loading={loading}
						removeObject={removeObject}
						addSymbol={addSymbol}
						engravingColor={engravingColor}
						addPhoto={addPhoto}
						allowedCustomization={allowedCustomization}
					/>
				)}
				{tab !== 'makeOwn' && <ChooseDesignSection productId={productId} selectDesign={addSelectedDesign} chosenDesign={chosenDesign} canvas={canvas} removeDesign={tryToRemoveDesign} setDisabledButton={setDisabledButton} engravingColor={engravingColor} />}
			</div>
			<DeleteCurrentDesignModal modalIsOpen={showDeleteModal} onConfirm={onConfirmResetDesign} onCancel={onCancel} saveDraft={onSaveDraft} />
		</div>
	);
};

export default NormalSideBar;
