import { useEffect, useState } from 'react';
import EditTextTopRowButtons from './EditTextTopRowButtons';
import { DEFAULT_COLOR, DEFAULT_FONT_SIZE, FONTS, TEXT_COLORS } from '../../../Constants';
import { ReactComponent as TextLeft } from '../../../assets/icons/textLeft.svg';
import { ReactComponent as TextCenter } from '../../../assets/icons/textCenter.svg';
import { ReactComponent as TextRight } from '../../../assets/icons/textRight.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import '../../../assets/style/Sidebar.css';
import { useTranslation } from 'react-i18next';

type EditTextType = {
	addText?: any;
	selectedObject: any;
	productId?: string;
	allowedFonts: any[];
	onBack?: () => void;
	renderAll: () => void;
	fitText: ({ object }: { object: any }) => void;
	removeObject: (object?: any) => void;
	engravingColor: string | null;
};
function EditText({ addText, onBack, renderAll, fitText, selectedObject, productId, allowedFonts, removeObject, engravingColor }: EditTextType) {
	const { t } = useTranslation();
	const [, setText] = useState<string>('');
	const [selectedFont, setSelectedFont] = useState(FONTS[0]);
	const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR);
	const [fontSize, setFontSize] = useState<any>(DEFAULT_FONT_SIZE);
	// const [allowedFonts, setAllowedFonts] = useState<any[]>([]);

	const [alignedText, setAlign] = useState('center');

	const alignText = (align: string) => {
		(selectedObject as fabric.Textbox).set({ textAlign: align });
		setAlign(align);
		renderAll();
	};

	useEffect(() => {
		if (selectedObject) {
			setText(selectedObject?.text ?? '');
			setSelectedFont(selectedObject?.fontFamily ?? FONTS[0]);
			setSelectedColor(selectedObject?.fill ?? TEXT_COLORS[0]);
			setFontSize(selectedObject?.fontSize ?? DEFAULT_FONT_SIZE);
			setAlign(selectedObject?.textAlign ?? 'center');
			// setExpanded(true);
		}
	}, [selectedObject]);

	// const changeText = (text: string) => {
	// 	selectedObject?.set({ text });
	// 	setText(text);
	// 	fitText(selectedObject);
	// 	renderAll();
	// };

	const changeFont = (fontFamily: string) => {
		selectedObject?.set({ fontFamily: fontFamily });
		setSelectedFont(fontFamily);
		fitText(selectedObject);
		renderAll();
	};

	const changeColor = (color: string) => {
		selectedObject?.set({ fill: color });
		setSelectedColor(color);
		renderAll();
	};
	const changeFontSize = (size: number) => {
		selectedObject?.set({ fontSize: size });
		fitText(selectedObject);
		setFontSize(size);
		renderAll();
	};

	return (
		<div>
			<EditTextTopRowButtons onBack={onBack} onButtonClick={addText} />
			<div className="pl-[8px]">
				<div>
					<div className="flex flex-row justify-between mt-[16px] mb-[13px] font-bold">
						<label htmlFor="style your text" className="uppercase">
							{t('style your text')}
						</label>
						<button onClick={() => removeObject(selectedObject)} className="px-[15px]">
							<Trash />
						</button>
					</div>
					{/* <div className="border-b border-b-[#D4C4F6] my-[30px]" /> */}
					<div className="">
						{!!allowedFonts.length && (
							<>
								<label htmlFor="font">Font</label>
								<div className="flex flex-row flex-wrap gap-[12px] mt-[16px] mb-[40px]">
									{allowedFonts.map((font: any) => (
										<FontButton key={font.id} fontFamily={font.name} selected={selectedFont === font.name} onSelect={changeFont} />
									))}
								</div>
							</>
						)}
						{!engravingColor && !!TEXT_COLORS.length && (
							<>
								<label htmlFor="text color">{t('TEXT COLOR')}</label>
								<div className="flex flex-row flex-wrap gap-[10px] mt-[16px] mb-[40px] ">
									{TEXT_COLORS.map((color) => (
										<ColorButton color={color} selected={selectedColor === color} onSelect={changeColor} key={color} />
									))}
								</div>
							</>
						)}
						{/* {!partiallyEnabled && ( */}
						<>
							<div className="flex flex-row justify-between">
								<label htmlFor="font size">{t('Font Size')}</label>
								<span>{fontSize}</span>
							</div>
							<div className="mb-[40px]">
								<input className="slider" type="range" min="10" max="70" value={fontSize} onChange={(e) => changeFontSize(parseInt(e.target.value))} />
							</div>
						</>
						{/* )} */}
						<label htmlFor="text color">{t('TEXT ALIGNMENT')}</label>
						<div className="flex flex-row gap-[20px] pt-[16px]">
							<button onClick={() => alignText('left')}>
								<TextLeft stroke={alignedText === 'left' ? '#0F2765' : '#D4C4F6'} />
							</button>
							<button onClick={() => alignText('center')}>
								<TextCenter stroke={alignedText == 'center' ? '#0F2765' : '#D4C4F6'} />
							</button>
							<button onClick={() => alignText('right')}>
								<TextRight stroke={alignedText == 'right' ? '#0F2765' : '#D4C4F6'} />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export const FontButton = ({ selected, fontFamily = FONTS[0], onSelect = () => {} }: { selected?: boolean; fontFamily?: string; onSelect?: (font: string) => void }) => {
	return (
		<button className={`font-button ${selected ? 'selected' : ''}`} style={{ fontFamily }} onClick={() => onSelect(fontFamily)}>
			Aa
		</button>
	);
};

export const ColorButton = ({ selected, color = TEXT_COLORS[0], onSelect }: { selected?: boolean; color: string; onSelect: (color: string) => void }) => {
	return (
		<button className={`color-button-ring ${selected ? 'selected' : ''}`} onClick={() => onSelect(color)}>
			<div
				className="color-button"
				style={{
					backgroundColor: color,
					borderColor: color === '#FFFFFF' ? '#ECECEC' : color,
				}}
			></div>
		</button>
	);
};
export default EditText;
