import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from '../i18n';
import { getZoneById } from '../server/server';
const AppContext = createContext<any>(undefined);

const AppContextProvider = (props: any) => {
	const _zone = !!localStorage.getItem('zone_data') ? JSON.parse(localStorage.getItem('zone_data') ?? '{}') : null;

	const [zone, setZone] = useState<any>(_zone);
	const [zoneList, setZoneList] = useState<any[]>([]);

	const selectZone = (zone: any) => {
		localStorage.setItem('zone_data', JSON.stringify(zone));
		const language = zone?.language?.locale ?? 'sr';
		localStorage.setItem('locale', language);
		i18n.changeLanguage(language);
		document.documentElement.lang = language;
		setZone(zone);
	};

	useEffect(() => {
		if (!!_zone) setInitZone(_zone.id);
	}, []);

	const setInitZone = async (zoneId: string) => {
		const zoneResp = await getZoneById(zoneId);
		if (zoneResp.status === 200) {
			selectZone(zoneResp.data);
		}
	};

	return <AppContext.Provider value={{ zone, selectZone, zoneList, setZoneList }} {...props} />;
};

function useAppContext() {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error(`useAppContext must be used within a AppContextProvider`);
	}

	return context;
}

export { AppContextProvider, useAppContext };
