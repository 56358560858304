import React, { ReactElement, useRef, useState } from 'react';
import { ReactComponent as ButtonShadow } from '../../../assets/icons/buttonShadow.svg';
import { ReactComponent as AddTextIcon } from '../../../assets/icons/addText.svg';
import { ReactComponent as AddSymbol } from '../../../assets/icons/addSymbol.svg';
import { ReactComponent as AddPhoto } from '../../../assets/icons/addPhoto.svg';
import { ReactComponent as AddSymbolActive } from '../../../assets/icons/addSymbolActive.svg';
import Loading from '../../../common/Loading';
import { uploadFile } from '../../../server/server';
import { useTranslation } from 'react-i18next';
type Type = {
	selectedState?: string;
	addText?: ({ fontFamily, text }: { fontFamily?: string; text?: string }) => void;
	openSymbols?: () => void;
	addPhoto: (image: string) => void;
	allowedCustomization: {
		text: boolean;
		image: boolean;
		symbol: boolean;
		allowPersonalization: boolean;
	};
};
function InitButtons({ selectedState = '', addText = () => {}, openSymbols, addPhoto, allowedCustomization }: Type) {
	const { t } = useTranslation();
	const [uploadingImage, setUploadingImage] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const onImageUpload = async (event: any) => {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		setUploadingImage(true);
		const file = event.target.files[0];
		const resp = await uploadFile({ file: file, selectable: 'false' });
		if (resp.status === 201) {
			const image = resp.data;
			addPhoto(image?.id);
		}
		setUploadingImage(false);
	};

	const addPhotoTrigger = () => fileInputRef.current?.click();

	return (
		<div className="flex flex-row gap-[10px] pt-[14px]">
			{!!allowedCustomization.text && <Button text={t('Add text')} icon={<AddTextIcon />} onClick={addText} />}
			{!!allowedCustomization.symbol && <Button text={t('Add symbol')} icon={selectedState === 'addSymbol' ? <AddSymbolActive /> : <AddSymbol />} onClick={openSymbols} />}
			{!!allowedCustomization.image && <Button text={t('Add Photo')} icon={uploadingImage ? <Loading height={44} /> : <AddPhoto />} onClick={addPhotoTrigger} />}
			<input type="file" accept="image/png, image/jpeg, image/jpg" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
		</div>
	);
}

const Button = ({ text = '', icon = null, onClick = () => {} }: { text?: string; icon?: ReactElement | null; onClick?: (data: any) => void }) => {
	return (
		<button className="flex flex-col gap-[8px] items-center justify-center w-[170px] h-[113px]" onClick={onClick}>
			{icon}
			<p className="text-[16px] ">{text}</p>
			<ButtonShadow />
		</button>
	);
};

export default InitButtons;
