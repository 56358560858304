import React from 'react';
import AppStoreButtons from './AppStoreButtons';
import { useTranslation } from 'react-i18next';

function FooterBanner() {
	const { t } = useTranslation();
	return (
		<div className="lg:min-h-[570px] flex items-center lg:justify-center bg-pink w-full py-[22px] px-[40px] xl:px-0">
			<div className="flex flex-col  lg:flex-row items-center  h-full justify-center xl:gap-[100px] md:gap-[50px] transition-[gap] ease-in-out">
				<div className="flex-2 flex flex-col gap-[30px] w-full xl:max-w-[566px] justify-center">
					<h2 className="font-bold text-[46px] leading-[54px]">{t('Create your products with our application.')}</h2>
					<p className="lg:pr-[90px] text-[14px]">{t('Dive into the realm of creativity with our innovative application that puts the power of design into your hands! Unleash your imagination and craft personalized gifts that reflect your unique style.')}</p>
					<p className="lg:pr-[90px] text-[14px]">{t('footer_banner_last_section')}</p>
					<div className="flex flex-row items-center gap-[10px]">
						<AppStoreButtons />
					</div>
				</div>
				<div className="flex items-center justify-center flex-0 ">
					<img src={require('../assets/images/FooterBanner.webp')} loading="lazy" alt="Footer" className=" h-[100%] self-center max-h-[526px]" title="Footer Banner" />
				</div>
			</div>
		</div>
	);
}

export default FooterBanner;
