import { useEffect, useState } from 'react';
import ExpandedModule from '../Common/ExpandedModule';
import { DEFAULT_COLOR, FONTS, TEXT_COLORS } from '../../../Constants';
import { ColorButton, FontButton } from '../MakeYourOwnComponents/EditText';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as TextLeft } from '../../../assets/icons/textLeft.svg';
// import { ReactComponent as TextCenter } from '../../../assets/icons/textCenter.svg';
// import { ReactComponent as TextRight } from '../../../assets/icons/textRight.svg';
type Type = {
	obj: any;
	canvas?: any;
	initExpanded?: boolean;
	engravingColor?: string | null;
	fitText?: (obj: any) => void;
};

function EditText({ obj, canvas, initExpanded = false, engravingColor, fitText = () => {} }: Type) {
	const { t } = useTranslation();
	const [text, setText] = useState<string>('');
	const [selectedFont, setSelectedFont] = useState(FONTS[0]);
	const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR);
	const allowedFonts = obj?.allowedFonts ?? [];
	// const [expanded, setExpanded] = useState(initExpanded);

	const renderAll = () => canvas?.requestRenderAll();

	const [, setAlign] = useState('center');
	// const fitText = (a: any) => {};

	// const alignText = (align: string) => {
	// 	(obj as fabric.Textbox).set({ textAlign: align });
	// 	setAlign(align);
	// 	renderAll();
	// };

	useEffect(() => {
		if (obj) {
			setText(obj?.text ?? '');
			if (!!engravingColor) changeColor(engravingColor);
			else setSelectedColor(obj?.fill ?? TEXT_COLORS[0]);
			setSelectedFont(obj?.fontFamily ?? FONTS[0]);
			setAlign(obj?.textAlign ?? 'center');
		}
	}, [obj, engravingColor]);

	useEffect(() => {
		canvas?.on('text:changed', (e: any) => {
			const _obj = e.target;
			if (_obj.id === obj.id) setText(_obj.text);
		});
	}, []);

	// useEffect(() => {
	// 	canvas.on('selection:created', (e: any) => {
	// 		const selectedObject = e.selected[0];
	// 		if (selectedObject.id == obj.id) {
	// 			setExpanded(true);
	// 		}
	// 	});

	// 	canvas.on('selection:updated', (e: any) => {
	// 		const selectedObject = e.selected[0];
	// 		if (selectedObject.id == obj.id) {
	// 			setExpanded(true);
	// 		}
	// 	});
	// }, []);

	const changeFont = (fontFamily: string) => {
		obj?.set({ fontFamily: fontFamily });
		setSelectedFont(fontFamily);
		const currentObj = canvas?.getObjects().find((o: any) => o.id === obj.id);
		if (currentObj) fitText(currentObj);
		renderAll();
	};

	const changeColor = (color: string) => {
		obj?.set({ fill: color });
		setSelectedColor(color);
		renderAll();
	};

	const selectText = () => {
		canvas?.setActiveObject(obj);
		renderAll();
	};

	const deselectText = () => {
		// canvas.setActiveObject(null);
		// renderAll();
	};

	const hideExpandIcon = allowedFonts.length === 1 && engravingColor !== null;

	return (
		<ExpandedModule title={t('Edit text')} subtitle={text} initValue={true} selectText={selectText} deselectText={deselectText} hideExpandIcon={hideExpandIcon}>
			{!hideExpandIcon && (
				<div>
					<div className="">
						<div>
							<div className="mt-[14px]">
								{allowedFonts.length > 1 && (
									<>
										<label htmlFor="font" className="text-[14px]">
											{t('Font')}
										</label>
										<div className="flex flex-row flex-wrap gap-[12px] mt-[16px] mb-[40px]">{allowedFonts?.map((font: any) => <FontButton key={font.id} fontFamily={font.name} selected={selectedFont === font.name} onSelect={changeFont} />)}</div>
									</>
								)}
								{engravingColor === null && (
									<>
										<label htmlFor="text color" className="text-[14px]">
											{t('Text color')}
										</label>
										<div className="flex flex-row flex-wrap gap-[10px] mt-[16px] mb-[40px] ">
											{TEXT_COLORS.map((color) => (
												<ColorButton color={color} selected={selectedColor === color} onSelect={changeColor} key={color} />
											))}
										</div>
									</>
								)}
								{/* <label htmlFor="text color" className="text-[14px]">
								Text alignment
							</label>
							<div className="flex flex-row gap-[20px] pt-[16px]">
								<button onClick={() => alignText('left')}>
									<TextLeft stroke={alignedText === 'left' ? '#0F2765' : '#D4C4F6'} />
								</button>
								<button onClick={() => alignText('center')}>
									<TextCenter stroke={alignedText == 'center' ? '#0F2765' : '#D4C4F6'} />
								</button>
								<button onClick={() => alignText('right')}>
									<TextRight stroke={alignedText == 'right' ? '#0F2765' : '#D4C4F6'} />
								</button>
							</div> */}
							</div>
						</div>
					</div>
				</div>
			)}
		</ExpandedModule>
	);
}

export default EditText;
