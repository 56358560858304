import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATION_EN from './translations/en';
import TRANSLATION_SR from './translations/sr';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: localStorage.getItem('locale') || 'en',
		debug: false,
		resources: {
			en: {
				translation: TRANSLATION_EN,
			},
			sr: {
				translation: TRANSLATION_SR,
			},
		},
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});
export default i18n;
