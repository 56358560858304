import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import NormalSideBar from './NormalSideBar';
import '../../assets/style/Configuration.css';
import Loading from '../../common/Loading';
import ConfiguratorBody from './ConfiguratorBody';

import { useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import { DEFAULT_COLOR, DEFAULT_NEW_TEXT, DEFAULT_TEXT_CONFIG, DELETE_CONTROL, FAKE_RESTRICTION_AREA, FONTS, HideControls, HideControlsTextbox, RESTRICTION_AREA_BORDER } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { createDraft, getDraftsById, getImage, getProductById, getProductByOptions, getProductDesign, uploadFile } from '../../server/server';
import { dataUrlToFile, debounce, getTextWithNewLines, useContainerDimensions } from '../../util/util';
import DefaultDesignSideBar from './DefaultDesignSideBar';
import ProductNameAndPrice from '../product/ProductNameAndPrice';
import BackButton from './Common/BackButton';
import SaveDraftButton from './Common/SaveDraftButton';
import { adjustTextFontSize, computeScaleFactor, drawRestrictionArea, fitText, fitTextFromDesign, rescalingTextBox, restrictMovement, restrictResizing, restrictRotating, restrictScaling } from '../../util/fabricCanvasEvents';
import { configureCroppingArea, configureDesignLabel, configureInitDesignItem, createGroupObj } from '../../util/fabricConfigDesignElements';
import { Textbox } from 'fabric/fabric-impl';
import { useUser } from '../../auth/UseUser';
import GiftBoxButton from './GiftBoxButton';
import { useTranslation } from 'react-i18next';
fabric.Object.NUM_FRACTION_DIGITS = 16;

const ProductConfiguration = forwardRef(
	(
		{
			goBack = () => {},
			productId = [],
			standAlone = false,
			productInfo = {},
			addItemToCart,
			editCartMode = false,
			cartElement = null,
			loading = false,
		}: { goBack?: () => void; productId?: any[]; standAlone: boolean; productInfo: any; addItemToCart: any; editCartMode: boolean; cartElement: any; loading: boolean },
		ref
	) => {
		const { t } = useTranslation();
		const method = productInfo?.options?.method?.toUpperCase() ?? 'PRINTING';

		const { editor, onReady } = useFabricJSEditor();
		const { user } = useUser();
		const [searchParams, setSearchParams] = useSearchParams();
		const [giftBoxAdded, setGiftBoxAdded] = useState(false);
		const [updateTextVersion, setTextVersion] = useState(new Date());
		const [canvasReady, setCavnasReady] = useState(false);
		const draftId = searchParams.get('draftId') ?? '';
		const fileInputRef = useRef<HTMLInputElement | null>(null);

		const [chosenDesign, setChosenDesign] = useState<any>(null);
		const chosenDesignRef = useRef(null);
		const selectedObjectInDesign = useRef<any>(null);
		const [showImageLoader, setImageLoader] = useState(false);

		const canvasContainerRef = useRef<HTMLDivElement | null>(null);
		const { width, height } = useContainerDimensions(canvasContainerRef);
		const [selectedObject, setSelectedObject] = useState<any>(null);
		const [init, setInit] = useState(false);
		const product = productInfo;
		// const [productInfo, setProductInfo] = useState<any>(productInfo);
		// const [product, setProduct] = useState<any>(productInfo);
		// const [loading, setLoading] = useState(false);
		const [orderProgress, setOrderProgress] = useState(false);
		const [addingToDraft, setAddingToDraft] = useState(false);
		const scaleFactorRef = useRef(1);
		const [scaleChange, setScaleChange] = useState(false);
		const [disabledButton, setDisabledButton] = useState(true);
		const hasDefaultDesign = product?.allowedDesigns?.length > 0 && !!product?.allowedDesigns[0]?.default;
		const designPrice = !!chosenDesign ? chosenDesign.price : 0;
		const isEngraving = !!productInfo?.options?.method;

		const productImage = useMemo(() => getImage(product?.restrictedImageId), [product]);
		const virtualRestrictionArea = useMemo(() => (loading && !product?.restricted ? FAKE_RESTRICTION_AREA : product?.restricted), [product, loading]);

		const engravingColor = useMemo(() => {
			if (productInfo?.options?.method === 'Engraving' && !!productInfo?.options?.engravingColor) return productInfo.options.engravingColor;
			return null;
		}, [productInfo]);

		const imgElement = useRef(document.createElement('img')).current;

		useEffect(() => {
			const handleSelection = () => setSelectedObject(editor?.canvas?.getActiveObject());
			const canvas = editor?.canvas;
			if (canvas) {
				canvas.on('selection:created', handleSelection);
				canvas.on('selection:updated', handleSelection);
				canvas.on('selection:cleared', handleSelection);
			}
			return () => {
				if (canvas) {
					canvas.off('selection:created', handleSelection);
					canvas.off('selection:updated', handleSelection);
					canvas.off('selection:cleared', handleSelection);
				}
			};
		}, [editor]);

		useEffect(() => {
			if (!loading && canvasReady) {
				fabric.Object.prototype.controls.deleteControl = DELETE_CONTROL;
				setSelectedObject(editor?.canvas?.getActiveObject());
				if (!init && !scaleChange) {
					imgElement.onload = async () => {
						try {
							const fabricImage = new fabric.Image(imgElement);

							const imageH = fabricImage?.height ?? 1;
							const imageW = fabricImage?.width ?? 1;
							const initW = computeScaleFactor(imageW, canvasContainerRef?.current?.clientWidth);
							const initH = computeScaleFactor(imageH, canvasContainerRef?.current?.clientHeight);
							const initScale = Math.min(initW, initH);
							scaleFactorRef.current = initScale;
							setScaleChange(true);
							fabricImage?.scale(initScale);
							editor?.canvas?.setWidth(fabricImage?.getScaledWidth());
							editor?.canvas?.setHeight(fabricImage?.getScaledHeight());
							editor?.canvas?.setBackgroundImage(fabricImage, editor?.canvas?.requestRenderAll.bind(editor?.canvas), {});
							if (hasDefaultDesign) await loadDefaultDesign();
							else drawRestrictionArea(virtualRestrictionArea, scaleFactorRef.current, editor?.canvas);
							if (!!draftId) getDraft();
							if (editCartMode) loadCartItems();
						} catch (e) {
							console.warn(e);
						}
					};
					imgElement.src = productImage;
					imgElement.crossOrigin = 'anonymous';
				}

				if (!init && !!editor) {
					editor?.canvas?.on('object:moving', (event) => {
						const obj = event.target;
						if (!obj) return;
						if (obj?.name === 'imageInClippingArea') return;
						restrictMovement(obj, getRestrictionMargin());
						editor?.canvas?.requestRenderAll();
					});
					editor?.canvas?.on('object:scaling', (event) => {
						const obj = event.target;
						if (!obj) return;
						if (obj?.name === 'imageInClippingArea') return;
						restrictScaling(obj, getRestrictionMargin());
						editor?.canvas?.requestRenderAll();
					});
					editor?.canvas?.on('object:resizing', (event) => {
						const obj = event.target;
						if (!obj) return;
						if (obj.type === 'textbox' && !chosenDesignRef.current) {
							restrictResizing(obj, getRestrictionMargin());
							editor?.canvas?.requestRenderAll();
						}
					});
					// editor?.canvas?.on('object:resizing', (event) => {
					// 	const obj = event.target;
					// 	if (!obj) return;
					// 	if (obj.type === 'textbox' && !!chosenDesignRef.current) {
					// 		restrictResizing(obj, getRestrictionMargin());
					// 		editor?.canvas?.requestRenderAll();
					// 	}
					// });
					editor?.canvas?.on('object:rotating', (event) => {
						const obj: any = event.target;
						if (!obj || obj?.name === 'imageInClippingArea') return;
						restrictRotating(obj, getRestrictionMargin());
						editor?.canvas?.requestRenderAll();
					});

					editor?.canvas?.on('text:changed', function (e) {
						var obj: any = e.target;
						if (obj && obj.type === 'textbox' && !!chosenDesignRef.current) {
							adjustTextFontSize(obj, obj.maxWidth, obj.maxHeight, scaleFactorRef.current);
						} else if (!chosenDesignRef.current) {
							const availableHeight = calculateAvailableHeight(obj);
							fitTextFromDesign(obj, availableHeight, editor);
							editor?.canvas?.requestRenderAll();
						}
					});

					editor?.canvas?.on('object:removed', function () {
						const numberOfObjects = editor.canvas.getObjects().length;
						setDisabledButton(numberOfObjects === 1);
					});

					setInit(true);
				}
			}
		}, [editor, computeScaleFactor, loading, scaleChange, canvasReady]);

		// useEffect(() => {
		// 	if (!loading) {
		// 		const debouncedRecalculate = debounce(() => {
		// 			recalculateScaleAndRestriction();
		// 		}, 200);
		// 		window.addEventListener('resize', debouncedRecalculate);
		// 		return () => window.removeEventListener('resize', debouncedRecalculate);
		// 	}
		// }, [loading]);

		// const recalculateScaleAndRestriction = () => {
		// 	if (canvasContainerRef.current) {
		// 		const fabricImage = new fabric.Image(imgElement);
		// 		const imageW = fabricImage?.width ?? 1;
		// 		// const initScale = computeScaleFactor(imageW, canvasContainerRef.current);
		// 		// scaleFactorRef.current = initScale;
		// 		setScaleChange(true);
		// 		// fabricImage?.scale(initScale);
		// 		editor?.canvas?.setWidth(fabricImage?.getScaledWidth());
		// 		editor?.canvas?.setHeight(fabricImage?.getScaledHeight());
		// 		editor?.canvas?.setBackgroundImage(fabricImage, editor?.canvas?.requestRenderAll.bind(editor?.canvas), {});
		// 		drawRestrictionArea(virtualRestrictionArea, scaleFactorRef.current, editor?.canvas);
		// 	}
		// };

		const loadCartItems = async () => {
			// await sleep(2000);
			if (!!cartElement.chosenDesign) {
				setChosenDesign(cartElement.chosenDesign);
				chosenDesignRef.current = cartElement.chosenDesign;
				editor?.canvas?.remove(...editor?.canvas?.getObjects());
			}
			fabric?.util?.enlivenObjects(
				cartElement.design,
				(objs: any) => {
					objs.forEach((e: any) => loadItemToCanvas(e, !!cartElement.chosenDesign, true));
					editor?.canvas?.requestRenderAll();
				},
				''
			);
			setDisabledButton(false);
		};

		const getDraft = async () => {
			const resp = await getDraftsById(draftId);
			if (resp.status === 200) {
				const data: any = resp.data;
				if (!!data.productDesign) {
					setChosenDesign(data.productDesign);
					chosenDesignRef.current = data.productDesign;
					const item: any = editor?.canvas?.getObjects()?.filter((e: any) => e.name === 'restriction_area')[0];
					editor?.canvas.remove(item);
				}
				fabric?.util?.enlivenObjects(
					data.design,
					(objs: any) => {
						objs.forEach((item: any) => loadItemToCanvas(item, !!data.productDesign, true));
						editor?.canvas?.requestRenderAll();
					},
					''
				);
				setDisabledButton(false);
			}
		};

		const loadItemToCanvas = (item: any, designIncluded: boolean, cart = false) => {
			let obj: any;
			if (item.frame) {
				const frame = new fabric.Object(item.clipPath);
				(frame as any).set({
					strokeWidth: 1,
					stroke: 'white',
					skip: true,
					event: false,
					selectable: false,
					hoverCursor: 'default',
					angle: item?.angle ?? 0,
					fill: 'white',
				});
				editor?.canvas?.add(frame);
			}
			if (designIncluded) {
				obj = configureInitDesignItem(item, scaleFactorRef.current, cart);
				if (item.type === 'textbox') {
					obj.set({ ...DEFAULT_TEXT_CONFIG, fill: item.fill, lockScalingFlip: true });
					obj.setControlsVisibility(HideControls);
					// obj.setControlVisible('deleteControl', true);
				}
				if (obj.type === 'textbox') {
					obj = configureDesignLabel(obj, obj.textBoxEditable, obj.partiallyEditable, obj?.allowedFonts ?? [], editor?.canvas, scaleFactorRef.current);
					(obj as any).set({ fontSize: item.fontSize * scaleFactorRef.current });
				}
				if (obj.name === 'croppingArea') obj = configureCroppingArea(obj, onSelectCroppingArea, t);
				if (obj.name === 'imageInClippingArea') {
					obj.set({
						lockMovementX: false,
						lockMovementY: false,
						selectable: true,
						evented: true,
					});

					(obj as any).createObjFunction = () => createGroupObj(obj, onSelectCroppingArea, t);
				}
			} else {
				obj = new fabric.Object(item);
				const scaleFactor = scaleFactorRef.current;
				obj.set({
					scaleX: (obj.scaleX || 1) * scaleFactor,
					scaleY: (obj.scaleY || 1) * scaleFactor,
					left: (obj.left || 1) * scaleFactor,
					top: (obj.top || 1) * scaleFactor,
					crossOrigin: 'anonymous',
				});
				if (item.type === 'textbox') {
					(obj as any).set({ fontSize: item.fontSize * scaleFactor });
					(obj as any).set({ ...DEFAULT_TEXT_CONFIG, fill: item.fill, lockScalingFlip: true, editingBorderColor: 'white' });
					obj.setControlsVisibility(HideControlsTextbox);
					obj.setControlVisible('deleteControl', true);
					obj.on('resizing', function () {
						rescalingTextBox(obj, getRestrictionMargin());
					});
				}
			}

			editor?.canvas?.add(obj);
		};

		const loadDefaultDesign = async () => {
			const resp = await getProductDesign(product?.allowedDesigns[0]?.productDesign?.id);
			if (resp.status === 200) {
				addDesign({ design: resp.data });
			}
		};

		const addDesign = async ({ design }: { design: any }) => {
			setChosenDesign(design);
			chosenDesignRef.current = design;
			editor?.canvas?.remove(...editor?.canvas?.getObjects());

			// Process layers in sequence instead of in parallel
			for (const layer of design.layers.reverse()) {
				await new Promise<void>((resolve) => {
					fabric?.util?.enlivenObjects(
						[layer.design],
						(objs: any) => {
							objs.forEach((item: any) => {
								const scaleFactor = scaleFactorRef.current;
								let obj = configureInitDesignItem(item, scaleFactor);

								if (!!layer.printingMethod) (obj as any).printingMethod = layer.printingMethod;
								if (item.type === 'textbox') {
									obj.set({ ...DEFAULT_TEXT_CONFIG, fill: item.fill, lockScalingFlip: true });
									obj.setControlsVisibility(HideControls);
									(obj as any).set({ fontSize: item.fontSize });
									(obj as any).set({ placeholder: (obj as any).text ?? '' });

									(obj as fabric.Textbox).onKeyDown = (e: KeyboardEvent) => {
										if (e.code === 'Enter') {
											e.preventDefault();
										}
									};
									(obj as any).on('selected', function (e: any) {
										if ((obj as any).text === (obj as any).placeholder) {
											(obj as any).set('text', '');
										}
										const allObjects = editor?.canvas?.getObjects();
										const textObjects = allObjects?.find((e: any) => e.textId == (obj as any)?.id);
										textObjects?.set('strokeWidth', 1);
										textObjects?.set('stroke', '#E5DDF6');
										textObjects?.set('fill', 'transparent');
										editor?.canvas?.requestRenderAll();
										(obj as any).enterEditing();
									});
									(obj as any).onDeselect = (e: any) => {
										if ((obj as any).text == '') {
											(obj as any).set('text', (obj as any).placeholder);
										}
										const allObjects = editor?.canvas?.getObjects();
										const textObjects = allObjects?.find((e: any) => e.textId == (obj as any)?.id);
										textObjects?.set('strokeWidth', 0);
										textObjects?.set('fill', 'transparent');
										editor?.canvas?.requestRenderAll();
										(obj as any).exitEditing();
									};
								}
								if (layer.layerType === 'label') obj = configureDesignLabel(obj, layer.editable, layer.partiallyEditable, layer?.allowedFonts ?? [], editor?.canvas, scaleFactor);
								if (layer.layerType === 'croppingArea') {
									obj = configureCroppingArea(obj, onSelectCroppingArea, t);
								}
								editor?.canvas?.add(obj);
							});
							editor?.canvas?.requestRenderAll();
							resolve(); // Resolve the promise after this layer is processed
						},
						''
					);
				});
			}
		};

		const onSelectCroppingArea = (e: any) => {
			const obj = e.target;
			if (!!fileInputRef.current) fileInputRef.current.value = '';

			selectedObjectInDesign.current = obj;
			fileInputRef.current?.click();
			editor?.canvas.discardActiveObject();
			editor?.canvas?.requestRenderAll();
		};

		const getRestrictionMargin = () => {
			const scale = scaleFactorRef.current;
			const realRestrictionArea = virtualRestrictionArea.map((val: any) => val * scale);

			const computedArea = {
				left: realRestrictionArea[0],
				top: realRestrictionArea[1],
				width: realRestrictionArea[2],
				height: realRestrictionArea[3],
			};

			return {
				left: computedArea.left + RESTRICTION_AREA_BORDER,
				right: computedArea.left + computedArea.width,
				top: computedArea.top + RESTRICTION_AREA_BORDER,
				bottom: computedArea.top + computedArea.height,
				width: computedArea.width,
				height: computedArea.height,
			};
		};

		const calculateAvailableHeight = (textbox: fabric.Textbox) => {
			const restrictionMargin = getRestrictionMargin();
			return restrictionMargin.height - ((textbox?.top ?? 0) - restrictionMargin.top);
		};

		const isNearLimit = (textbox: fabric.Textbox) => {
			const availableHeight = calculateAvailableHeight(textbox);
			return (textbox.height ?? 0) > availableHeight * 0.9; // 90% of available space
		};

		const onAddText = ({ text = t('New Text'), fontFamily = FONTS[0] }: { text?: string; fontFamily?: string }) => {
			let textTopPosition = getRestrictionMargin().top + 20 + (editor?.canvas.getObjects()?.length ?? 0) * 40;

			if (textTopPosition + 20 > getRestrictionMargin().top + getRestrictionMargin().height) textTopPosition = getRestrictionMargin().top + 20;

			const textComponent = new fabric.Textbox(text, {
				left: getRestrictionMargin().left + 1,
				top: textTopPosition,
				...DEFAULT_TEXT_CONFIG,
				width: getRestrictionMargin().width - 4,
				editingBorderColor: 'white',
				fill: !!engravingColor ? engravingColor : DEFAULT_COLOR,
				fontFamily,
				splitByGrapheme: true,
			});

			(textComponent as any).printingMethod = method;

			// textComponent.on('resizing', function () {
			// 	rescalingTextBox(textComponent, getRestrictionMargin());
			// });

			(textComponent as any).set({ placeholder: (textComponent as any).text ?? '' });
			textComponent.caching = false;

			(textComponent as any).on('editing:entered', function (e: any) {
				if ((textComponent as any).text === (textComponent as any).placeholder) {
					(textComponent as any).set('text', '');
					(textComponent as any).hiddenTextarea.value = ''; // Ensure the hidden textarea also clears its value
					textComponent.enterEditing();
				} else {
					(textComponent as any).enterEditing();
					editor?.canvas?.requestRenderAll();
				}
			});

			(textComponent as any).onDeselect = (e: any) => {
				if ((textComponent as any).text === '') {
					(textComponent as any).set('text', (textComponent as any).placeholder);
					editor?.canvas?.requestRenderAll();
				}
				(textComponent as any).exitEditing();
			};

			textComponent.setControlsVisibility(HideControlsTextbox);

			textComponent.setControlVisible('deleteControl', true);
			textComponent.controls.deleteControl = DELETE_CONTROL;
			editor?.canvas?.add(textComponent);
			editor?.canvas?.setActiveObject(textComponent);
			editor?.canvas?.requestRenderAll();
			setDisabledButton(false);
		};

		const addSymbol = ({ symbol = '', imageId = '' }) => {
			fabric.Image.fromURL(
				symbol,
				(img) => {
					const maxScaleX = (getRestrictionMargin().width * 2) / 3 / (img.width ?? 1);
					const maxScaleY = (getRestrictionMargin().height * 2) / 3 / (img.height ?? 1);
					const scale = Math.min(maxScaleX, maxScaleY);

					img?.set({ left: getRestrictionMargin().left + 50, top: getRestrictionMargin().top + 50, scaleX: scale, scaleY: scale, lockScalingFlip: true, name: 'symbol' });
					(img as any).set({ imageId: imageId });
					(img as any).printingMethod = method;
					editor?.canvas?.add(img);
					editor?.canvas?.requestRenderAll();
				},
				{ crossOrigin: 'anonymous' }
			);
			setDisabledButton(false);
		};
		const addPhoto = (id: string) => {
			fabric.Image.fromURL(
				getImage(id) as string,
				(img) => {
					const maxScaleX = getRestrictionMargin().width / (img.width ?? 1);
					const maxScaleY = getRestrictionMargin().height / (img.height ?? 1);
					const scale = Math.min(maxScaleX, maxScaleY);
					img?.set({ left: getRestrictionMargin().left, top: getRestrictionMargin().top, scaleX: scale, scaleY: scale, lockScalingFlip: true, name: 'userImage' });
					(img as any).set({ imageId: id });
					(img as any).printingMethod = method;
					editor?.canvas?.add(img);
					editor?.canvas?.requestRenderAll();
				},
				{ crossOrigin: 'anonymous' }
			);
			setDisabledButton(false);
		};

		const getNormalizedObjects = (addToCart = false) => {
			const scaleFactor = scaleFactorRef.current;
			const normalizedObjects = editor?.canvas
				?.getObjects()
				?.filter((e: any) => e.name !== 'restriction_area' && !e?.skip)
				?.map((e: any) => {
					// Normalize the properties based on the scale factor
					let normalizedObject = {
						...e.toObject(),
						// ...e,
						imageId: e.imageId ?? null,
						name: e.name ?? null,
						scaleX: e.scaleX / scaleFactor,
						scaleY: e.scaleY / scaleFactor,
						left: e.left / scaleFactor,
						top: e.top / scaleFactor,
						totalWidth: (e.width * e.scaleX) / scaleFactor,
						totalHeight: (e.height * e.scaleY) / scaleFactor,
						fontSize: (e?.fontSize ?? 1) / scaleFactor,
						rootObj: e.rootObj,
						textBoxEditable: !!e.textBoxEditable,
						partiallyEditable: !!e.partiallyEditable,
						allowedFonts: e?.allowedFonts,
						frame: e?.frame ?? null,
						printingMethod: e?.printingMethod ?? null,
					};
					if (!chosenDesign && e.type === 'textbox' && addToCart) {
						const text = getTextWithNewLines(e, !chosenDesignRef?.current);
						normalizedObject.text = text;
						console.log('Text: ', text);
					}
					return normalizedObject;
				});
			return normalizedObjects;
		};

		const saveDraft = async () => {
			setAddingToDraft(true);
			const normalizedObjects = getNormalizedObjects();
			const dataURL = getCanvasPreviewURL();
			const file = dataUrlToFile(dataURL);
			const imageResp = await uploadFile({ file: file, selectable: 'false' });
			if (imageResp.status === 201) {
				const data = {
					productId: product.id,
					design: normalizedObjects,
					// usedImageIds: normalizedObjects?.filter((e) => e.name === 'imageInClippingArea').map((e) => e.imageId),
					previewImageId: imageResp.data.id,
					productDesignId: !!chosenDesign ? chosenDesign?.id : null,
				};
				const resp = await createDraft(data);
				if (resp.status === 201) {
					window.alert('Draft saved');
					setAddingToDraft(false);
				} else {
					return false;
				}
			}
		};

		const getCanvasPreviewURL = () => {
			const currentObjects = editor?.canvas.getObjects() ?? [];
			for (let e of currentObjects) if (e.name === 'restriction_area' || (e as any)?.skip) editor?.canvas.remove(e);

			const dataURL: any = editor?.canvas?.toDataURL({
				format: 'png',
				quality: 1.0,
			});
			editor?.canvas?.remove(...editor?.canvas?.getObjects());
			currentObjects.forEach((e: any) => {
				editor?.canvas.add(e);
			});

			return dataURL;
		};

		useImperativeHandle(ref, () => ({
			async addToCart() {
				console.log('Adding to cart');

				setOrderProgress(true);
				const normalizedObjects = getNormalizedObjects(true);

				const dataURL = getCanvasPreviewURL();
				const file = dataUrlToFile(dataURL);
				const resp = await uploadFile({ file: file, selectable: 'false' });
				if (resp.status === 201) {
					return {
						product,
						design: normalizedObjects,
						usedImageIds: normalizedObjects?.filter((e) => e.name === 'imageInClippingArea' || e.name === 'symbol' || e.name === 'userImage').map((e) => e.imageId),
						imagePreviewId: resp.data.id,
						chosenDesign,
						giftBoxAdded,
					};
				}
			},
		}));

		const removeObject = (object: any = selectedObject) => {
			editor?.canvas?.remove(object);
			editor?.canvas?.requestRenderAll();
		};

		const bringBackward = () => {
			editor?.canvas.getActiveObject()?.sendBackwards();
			editor?.canvas?.requestRenderAll();
		};
		const bringToBack = () => {
			editor?.canvas.getActiveObject()?.sendToBack();
			editor?.canvas?.requestRenderAll();
		};
		const bringForward = () => {
			editor?.canvas.getActiveObject()?.bringForward();
			editor?.canvas?.requestRenderAll();
		};
		const bringToFront = () => {
			editor?.canvas.getActiveObject()?.bringToFront();
			editor?.canvas?.requestRenderAll();
		};

		const resetChosenDesign = () => {
			setChosenDesign(null);
			chosenDesignRef.current = null;
			editor?.canvas?.remove(...editor?.canvas?.getObjects());
			drawRestrictionArea(virtualRestrictionArea, scaleFactorRef.current, editor?.canvas);
			setDisabledButton(true);
		};

		const onImageUpload = async (event: any) => {
			if (!event.target.files || event.target.files.length === 0) {
				selectedObjectInDesign.current = null;
				editor?.canvas.discardActiveObject();
				editor?.canvas?.requestRenderAll();
				return;
			}
			setImageLoader(true);
			const obj = selectedObjectInDesign.current;
			const file = event.target.files[0];
			const objects = (obj as any)?.getObjects() ?? [obj];
			const clipObject = objects[0];
			const tempObj = new fabric.Text('Test', { name: 'loading', top: -1000 });
			editor?.canvas?.remove(obj);

			editor?.canvas?.add(clipObject);
			editor?.canvas?.add(tempObj);
			editor?.canvas?.requestRenderAll();
			const rootObj = selectedObjectInDesign.current.rootObj;

			setTimeout(async () => {
				const resp = await uploadFile({ file: file, selectable: 'false' });
				if (resp.status === 201) {
					const image = resp.data;
					fabric.Image.fromURL(
						getImage(image.id) as string,
						(img) => {
							const maxScaleX = (obj as any).width / (img.width ?? 1);
							const maxScaleY = (obj as any).height / (img.height ?? 1);
							img.name = 'imageInClippingArea';
							(img as any).set({ imageId: image.id });
							const scale = Math.max(maxScaleX, maxScaleY);
							img?.set({ left: (obj as any).left, top: (obj as any).top, lockScalingFlip: true, scaleX: scale, scaleY: scale }); //scaleX: scale, scaleY: scale,
							const angle = clipObject.angle;
							var conf: any = {
								width: (obj as any).maxWidth,
								height: (obj as any).maxHeight,
								top: rootObj.top,
								left: rootObj.left,
								absolutePositioned: true,
								scaleX: clipObject.scaleX,
								scaleY: clipObject.scaleY,
								angle: angle, // clipObject.angle,
							};

							var clipPath2;
							if (clipObject.type === 'rect') {
								conf = { ...conf, width: clipObject.width, height: clipObject.height };
								clipPath2 = new fabric.Rect(conf);
							} else if (clipObject.type === 'circle') {
								const radius = (clipObject.width / 2) * clipObject.scaleX; // Ako je širina jednaka visini
								const config = {
									left: (obj as any).left, // Postavljanje centra kruga
									top: (obj as any).top, // Postavljanje centra kruga
									radius: radius,
									absolutePositioned: true,
								};
								clipPath2 = new fabric.Circle(config);
							} else if (clipObject.type === 'path') {
								clipPath2 = new fabric.Path(clipObject.path, conf);
							} else return;
							img.clipPath = clipPath2;

							img.perPixelTargetFind = true;
							(img as any).crossOrigin = 'Anonymous';
							(img as any).targetFindTolerance = 4;
							img.hasControls = true;
							(img as any).createObjFunction = obj.createObjFunction;

							(img as any).rootObj = rootObj; //clipObject; // obj.rootObj;
							(img as any).angle = angle; // obj.rootObj;
							(img as any).printingMethod = rootObj.printingMethod ?? 'PRINTING';

							img.setControlVisible('deleteControl', false);
							const frame = new fabric.Object(clipPath2);
							const frameId = Date.now() + Math.random();
							(frame as any).set({
								strokeWidth: 1,
								stroke: 'white',
								skip: true,
								event: false,
								selectable: false,
								hoverCursor: 'default',
								angle: angle,
								fill: 'white',
								id: frameId,
							});
							(img as any).frame = clipPath2;
							(img as any).set({ frameId: frameId });
							// rootObj.set({ stroke: 'white' });
							// editor?.canvas?.add(rootObj);
							editor?.canvas?.remove(clipObject);
							editor?.canvas?.add(frame);
							editor?.canvas?.add(img);
							setImageLoader(false);
							editor?.canvas?.remove(tempObj);
							editor?.canvas.setActiveObject(img);
							editor?.canvas?.requestRenderAll();
							// selectedObjectInDesign.current = null;
						},
						{ crossOrigin: 'Anonymous' }
					);
				}
			}, 500);
		};

		const onCanvasReady = (c: any) => {
			onReady(c);
			setCavnasReady(true);
		};

		const renderAll = () => editor?.canvas?.requestRenderAll();

		const functions = {
			onAddText,
			fitText: (obj: any) => {
				if (!chosenDesign) return;
				adjustTextFontSize(obj, obj.maxWidth, obj.maxHeight, scaleFactorRef.current);
				editor?.canvas?.requestRenderAll();
			},
			addSymbol,
			// alignText,
			addPhoto,
			removeObject,
			// removeSelectedObject,
			setDisabledButton,
		};

		const isAddPackageButtonVisible = !!product?.commercialOptions?.packageAvailable;

		return (
			// <div className="flex flex-col xl:gap-[52px] gap-[20px] md:flex-row max-h-[588px] min-h-[588px] xl:pr-[70px] flex-1 pr-[20px]">
			<div className="flex flex-col xl:gap-[52px] gap-[20px] md:flex-row max-h-[760px] min-h-[760px] xl:pr-[70px] flex-1 pr-[20px]">
				<div className="flex-1 relative overflow-hidden" ref={canvasContainerRef}>
					<BackButton goBack={goBack} />
					{!disabledButton && !!user.email && <SaveDraftButton onClick={saveDraft} disabled={false} inProgress={addingToDraft} />}
					<ConfiguratorBody onReady={onCanvasReady} loading={loading} showAdditionalButtons={!!selectedObject && !chosenDesign} bringBackward={bringBackward} bringToBack={bringToBack} bringToFront={bringToFront} bringForward={bringForward} />
				</div>
				<div className="flex flex-col overflow-hidden md:max-w-[400px] xl:max-w-[523px] w-full ">
					<div className="pt-[16px] pb-[15px]">
						<ProductNameAndPrice name={product?.name} price={product?.commercialOptions?.price ?? -1} currency={product?.zone?.currency?.symbol} product={product} />
					</div>
					{!loading && (
						<div className=" flex flex-col flex-1">
							{!hasDefaultDesign && (
								<NormalSideBar
									allowedCustomization={{
										text: product?.options?.text,
										image: product?.options?.image,
										symbol: product?.options?.symbol,
										allowPersonalization: product?.options?.allowPersonalization === 'Yes',
									}}
									productId={product.id}
									product={productInfo}
									functions={functions}
									selectedObject={selectedObject}
									renderAll={renderAll}
									loading={loading}
									addDesign={addDesign}
									chosenDesign={chosenDesign}
									resetChosenDesign={resetChosenDesign}
									canvas={editor?.canvas}
									updateTextVersion={updateTextVersion}
									engravingColor={engravingColor}
									saveDraft={saveDraft}
									disabledButton={disabledButton}
									isAddPackageButtonVisible={isAddPackageButtonVisible}
								/>
							)}
							{hasDefaultDesign && (
								<DefaultDesignSideBar
									productId={product.id}
									product={product}
									functions={functions}
									selectedObject={selectedObject}
									renderAll={renderAll}
									loading={!init}
									addDesign={addDesign}
									chosenDesign={chosenDesign}
									resetChosenDesign={resetChosenDesign}
									canvas={editor?.canvas}
									updateTextVersion={updateTextVersion}
									engravingColor={engravingColor}
								/>
							)}
						</div>
					)}
					{isAddPackageButtonVisible && <GiftBoxButton giftAdded={giftBoxAdded} onPress={() => setGiftBoxAdded(!giftBoxAdded)} />}
					<div className="flex items-end justify-end mb-1">
						<button className={`rounded-full px-[62px] text-[white] h-[44px] text-bold min-w-[285px] items-center justify-center flex ${disabledButton ? 'bg-[#DFDFDF]' : 'bg-[#21187f]'}`} onClick={addItemToCart} disabled={disabledButton}>
							{orderProgress ? <Loading height={24} color="#0F2765" /> : <p>{editCartMode ? t('UPDATE CART') : `${t('ADD TO CART')} (${designPrice + parseFloat(product?.commercialOptions?.price)}${product?.zone?.currency?.symbol?.toLowerCase()})`}</p>}
						</button>
					</div>
				</div>
				<input type="file" accept="image/png, image/jpeg, image/jpg" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
				<canvas id="tempCanvas" className="hidden" />
			</div>
		);
	}
);

export default ProductConfiguration;
