import React, { useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../../assets/icons/expandIcon.svg';
type Type = {
	title: string;
	subtitle?: string;
	initValue?: boolean;
	children?: React.ReactNode;
	hideExpandIcon?: boolean;
	selectText?: () => void;
	deselectText?: () => void;
};

function ExpandedModule({ title, subtitle, initValue = false, children, deselectText, selectText, hideExpandIcon = false }: Type) {
	const [expanded, setExpanded] = useState(initValue);

	useEffect(() => {
		setExpanded(initValue);
	}, [initValue]);

	const toggle = (value: boolean) => {
		if (!hideExpandIcon) {
			setExpanded(value);
			if (selectText && deselectText) {
				if (value) selectText();
				else deselectText();
			}
		} else {
			if (selectText && deselectText) {
				selectText();
			}
		}
	};

	return (
		<div className="border border-[#F1F1FE] rounded-lg p-2">
			<button className="flex flex-row items-center justify-between w-full" onClick={() => toggle(!expanded)}>
				<label htmlFor={title + subtitle} className="text-[16px] font-bold cursor-pointer flex-1 text-left text-ellipsis overflow-hidden  whitespace-nowrap">
					{title}
					{!!subtitle && <span className="font-normal"> - {subtitle}</span>}
				</label>
				{!hideExpandIcon && (
					<div className={` ${expanded ? 'rotate-180' : ''}`}>
						<Arrow />
					</div>
				)}
			</button>
			{expanded && children}
		</div>
	);
}

export default ExpandedModule;
