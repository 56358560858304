import React from 'react';
import { ReactComponent as TechIcon } from '../../assets/icons/TechIcon.svg';
import { getImage } from '../../server/server';
import { useTranslation } from 'react-i18next';

function TechnicalSpecs({ specs = [] }: { specs?: any[] }) {
	const { t } = useTranslation();
	if (specs.length === 0) return null;
	return (
		<div className="mt-[90px] mb-[120px]">
			<div className="flex flex-col items-center">
				<div className="text-center text-[30px] font-normal">{t('Technical specs')}</div>
				<div className="mt-[46px] mx-auto flex flex-row gap-[70px] self-center">
					{specs?.map((spec: any, index) => (
						<div key={index}>
							<div className="w-[100px] h-[95px]">
								<img key={spec?.image?.id} src={getImage(spec?.image?.id)} alt="Design" className="duration-500 object-contain w-full h-full" />
							</div>
							<div className="mt-[16px] font-medium text-[20px] text-center">{spec.text}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default TechnicalSpecs;
