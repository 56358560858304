import React, { useState } from 'react';
import '../assets/style/ScrollingBanner.css'; // Uvezi CSS
import { ReactComponent as LaykaStar } from '../assets/icons/laykaStar.svg';
import { t } from 'i18next';

const ScrollingBanner = ({ text = t('BRING YOUR GIFTS TO LIFE'), animationDuration = '20s', height = 60, fontSize = 20 }: { text?: string; animationDuration?: string; height?: number; fontSize?: number }) => {
	const [array] = useState(new Array(100).fill(''));
	return (
		<div className="banner-container bg-[#21187F] flex flex-col justify-center" style={{ height, minHeight: height }}>
			<div className="scrolling-banner items-center" style={{ animationDuration }}>
				{array.map((e) => (
					<div className="flex flex-row gap-[6px] items-center">
						<span className="font-bold text-[white]" style={{ fontSize }}>
							{text}&nbsp;
						</span>
						<LaykaStar />
					</div>
				))}
			</div>
		</div>
	);
};

export default ScrollingBanner;
