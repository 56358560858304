import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface SEOProps {
	title?: string;
	description?: string;
	canonicalUrl?: string | null;
	imageUrl?: string;
	imageAlt?: string;
	keywords?: string;
	author?: string;
	language?: string;
	publishedDate?: string;
	modifiedDate?: string;
	price?: string;
	currency?: string;
	availability?: string;
	// New additions
	isArticle?: boolean;
	hasReviews?: boolean;
	averageRating?: string;
	reviewAuthor?: string;
	breadcrumbs?: Array<{ name: string; url: string }>;
	isPaginated?: boolean;
	prevPage?: string;
	nextPage?: string;
	urlType?: 'product' | 'category' | 'article' | 'homepage';
}

export const DEFAULT_TITLE = 'Layka - Discover unique products tailored just for you';
const DEFAULT_DESCRIPTION = 'Layka app transforms your shopping experience with personalized products tailored to your style and preferences. Discover unique, customizable items that reflect your individuality. Shop now for a truly personalized experience.';

export default function SEO({
	title = DEFAULT_TITLE,
	description = DEFAULT_DESCRIPTION,
	canonicalUrl = null,
	imageUrl,
	imageAlt,
	keywords,
	author = 'AIO PRO d.o.o.',
	language = 'en',
	publishedDate,
	modifiedDate,
	price,
	currency = 'USD',
	availability,
	isArticle = false,
	hasReviews = false,
	averageRating,
	reviewAuthor,
	breadcrumbs,
	isPaginated = false,
	prevPage,
	nextPage,
	urlType,
}: SEOProps) {
	const location = useLocation();
	const realCanonicalUrl = canonicalUrl ?? `https://laykagift.com${location.pathname}`;

	return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			<meta name="author" content={author} />
			<meta name="language" content={language} />
			<meta name="robots" content="index, follow" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			{realCanonicalUrl && <link rel="canonical" href={realCanonicalUrl} />}
			{/* End standard metadata tags */}
			{/* Facebook tags */}
			<meta property="og:type" content={isArticle ? 'article' : 'website'} />
			<meta property="og:site_name" content={title} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{imageUrl && <meta property="og:image" content={imageUrl} />}
			{imageAlt && <meta property="og:image:alt" content={imageAlt} />}
			<meta property="og:url" content={realCanonicalUrl} />
			{/* End Facebook tags */}
			{/* Twitter tags */}
			<meta name="twitter:creator" content="AIO PRO d.o.o." />
			<meta name="twitter:card" content="summary_large_image" /> {/* Fixed typo here */}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{imageUrl && <meta name="twitter:image" content={imageUrl} />}
			{imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
			{/* End Twitter tags */}
			{/* Pagination links */}
			{isPaginated && (
				<>
					{prevPage && <link rel="prev" href={prevPage} />}
					{nextPage && <link rel="next" href={nextPage} />}
				</>
			)}
			{/* Schema.org markup for products */}
			{urlType === 'product' && price && (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Product',
						name: title,
						description: description,
						image: imageUrl,
						offers: {
							'@type': 'Offer',
							price: price,
							priceCurrency: currency,
							availability: availability || 'https://schema.org/InStock',
						},
						brand: {
							'@type': 'Brand',
							name: 'Layka Gift',
						},
						...(publishedDate && { datePublished: publishedDate }),
						...(modifiedDate && { dateModified: modifiedDate }),
					})}
				</script>
			)}
			{/* Schema.org markup for reviews */}
			{hasReviews && averageRating && (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Review',
						itemReviewed: {
							'@type': 'Product',
							name: title,
						},
						reviewRating: {
							'@type': 'Rating',
							ratingValue: averageRating,
							bestRating: '5',
						},
						author: {
							'@type': 'Person',
							name: reviewAuthor || author,
						},
					})}
				</script>
			)}
			{/* Schema.org markup for breadcrumbs */}
			{breadcrumbs && breadcrumbs.length > 0 && (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'BreadcrumbList',
						itemListElement: breadcrumbs.map((crumb, index) => ({
							'@type': 'ListItem',
							position: index + 1,
							name: crumb.name,
							item: crumb.url,
						})),
					})}
				</script>
			)}
			{/* Schema.org markup for articles */}
			{isArticle && (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Article',
						headline: title,
						image: imageUrl,
						author: {
							'@type': 'Person',
							name: author,
						},
						publisher: {
							'@type': 'Organization',
							name: 'Layka Gift',
							logo: {
								'@type': 'ImageObject',
								url: 'https://laykagift.com/logo.png',
							},
						},
						datePublished: publishedDate,
						dateModified: modifiedDate || publishedDate,
					})}
				</script>
			)}
		</Helmet>
	);
}
