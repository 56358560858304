import React, { useEffect, useState } from 'react';
import EditDesignHeader from './EditDesignHeader';
import UploadImageButton from './UploadImageButton';
import ShowImagePreview from './ShowImagePreview';
import EditText from './EditText';
import ManageImages from './ManageImages';

type Type = {
	design: any;
	canvas?: any;
	removeDesign?: () => void;
	setDisabledButton: (value: boolean) => void;
	fitText?: (obj: any) => void;
	hideHeader?: boolean;
	engravingColor?: string | null;
};

function EditDesign({ design, canvas, removeDesign, setDisabledButton, hideHeader = false, engravingColor = null, fitText = () => {} }: Type) {
	const [objects, setObjects] = useState<any>([]);
	const [selectedObject, setSelectedObject] = useState<any>({});

	useEffect(() => {
		// setTimeout(() => {
		canvas?.on('object:added', detectChange);
		canvas?.on('object:removed', detectChange);
		canvas?.on('object:modified', detectChange);
		canvas.on('selection:created', detectSelectedObject);
		canvas.on('selection:updated', detectSelectedObject);

		detectChange();
		// }, 1000);
	}, []);

	const detectChange = () => {
		const objects = (canvas as any).getObjects();
		const uploadImageObjects = objects?.filter((e: any) => e?.name === 'group');
		setDisabledButton(uploadImageObjects.length !== 0);
		setObjects(objects);
	};
	const detectSelectedObject = () => {
		const object = (canvas as any).getActiveObject();
		setSelectedObject(object);
	};

	const textObjects = objects?.filter((e: any) => e?.type === 'textbox' && !!e?.editable);
	const imageObjects = objects?.filter((e: any) => e?.name === 'imageInClippingArea');
	const uploadImageObjects = objects?.filter((e: any) => e?.name === 'group');
	const loadingImages = objects?.filter((e: any) => e?.name === 'loading');

	return (
		<div>
			{!hideHeader && <EditDesignHeader design={design} removeObject={removeDesign} />}
			<div className="flex flex-col gap-[16px] pt-[24px]">
				<div className="flex flex-col gap-[16px]">
					{textObjects.map((e: any) => (
						<EditText key={e} obj={e} canvas={canvas} initExpanded={(textObjects.length === 1 && imageObjects.length === 0 && uploadImageObjects.length === 0) || selectedObject?.id == e?.id} engravingColor={engravingColor} fitText={fitText} />
					))}
				</div>
				{(!!imageObjects.length || !!uploadImageObjects.length || !!loadingImages.length) && <ManageImages canvas={canvas} imageObjects={imageObjects} uploadImageObjects={uploadImageObjects} loadingImages={loadingImages} />}
			</div>
		</div>
	);
}

export default EditDesign;
