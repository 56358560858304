import React, { useEffect } from 'react';
import ScrollingBanner from './common/ScrollingBanner';
import AppStoreButtons from './common/AppStoreButtons';
import { useTranslation } from 'react-i18next';
import SEO from './components/seo/seo';

function MobileScreen() {
	const { t } = useTranslation();

	useEffect(() => {
		document.documentElement.style.backgroundColor = '#f89dbd';
		document.body.style.backgroundColor = '#f89dbd';

		return () => {
			document.documentElement.style.backgroundColor = '';
			document.body.style.backgroundColor = '';
		};
	}, []);

	return (
		<div className="flex h-[100dvh] overflow-hidden flex-col">
			<div className="flex-grow bg-pink px-[20px] pt-[20px] xs:pt-[60px] pb-[20px] flex flex-col gap-[20px] overflow-y-auto">
				<h1 className="text-[27px] font-bold leading-[30px]">{t('Create your products with our application')}</h1>
				<p className="text-[14px] leading-[18px]">
					{t(
						"Dive into a sea of options and whip up a gift that's cool, original, and totally your vibe. Forget boring, generic gifts – now you're the gift design boss! Finding the perfect present has never been this fun. With a super easy-to-use interface (seriously, easier than scrolling your feed) and tons of design tricks up its sleeve, our app turns every idea into a masterpiece. Maximum personalization – all at your fingertips!"
					)}
				</p>
				<div className="flex flex-row items-center gap-[10px] justify-around w-full">
					<AppStoreButtons />
				</div>
				<div className="flex-grow flex items-center justify-center min-h-0 overflow-hidden ">
					<img src={require('./assets/images/FooterBanner.webp')} loading="lazy" alt="Footer" width={600} className="max-h-full max-w-full object-contain" title="Footer Banner" />
				</div>
			</div>
			<div className="flex-shrink-0">
				<ScrollingBanner text={t('download the app').toUpperCase()} fontSize={16} height={49} />
			</div>
			<SEO imageAlt="LaykaGift" imageUrl={require('./assets/images/FooterBanner.webp')} />
		</div>
	);
}

export default MobileScreen;
