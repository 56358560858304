const TRANSLATION_SR = {
	'Create your products with our application': 'Napravi svoj personalizovan poklon sa našom aplikacijom.',
	"Dive into a sea of options and whip up a gift that's cool, original, and totally your vibe. Forget boring, generic gifts – now you're the gift design boss! Finding the perfect present has never been this fun. With a super easy-to-use interface (seriously, easier than scrolling your feed) and tons of design tricks up its sleeve, our app turns every idea into a masterpiece. Maximum personalization – all at your fingertips!":
		'Zaron u more opcija i složi poklon koji je kul, originalan i totalno tvoj fazon. Nema više bezličnih poklona iz komšiluka – sada ti caruješ! Pronaći savršen poklon nikad nije bilo ovako zabavno.Sa ultra jednostavnim interfejsom (da ne kažemo - lakše nego skrolovanje mreža) i gomilom dizajnerskih fora, naša aplikacija pretvara svaku ideju u remek-delo. Personalizacija na max – sve ti je pod prstima!',
	'download the app': 'preuzmi aplikaciju',
	'Trending now': 'Najtrendi pokloni',
	'Create your products with our application.': 'Napravi svoj personalizovan poklon sa našom aplikacijom.',
	'Dive into the realm of creativity with our innovative application that puts the power of design into your hands! Unleash your imagination and craft personalized gifts that reflect your unique style.':
		'Upadaj u kreativni svet naše lude aplikacije i preuzmi dizajnersku moć u svoje ruke! Pusti mašti na volju i skockaj personalizovane poklone online – i to u realnom vremenu vidi kako izgledaju. Nema više "šta ako", ovde odmah vidiš kako tvoj genijalni poklon oživljava!',
	footer_banner_last_section:
		'Zaron u more opcija i složi poklon koji je kul, originalan i totalno tvoj fazon. Nema više bezličnih poklona iz komšiluka – sada ti caruješ! Pronaći savršen poklon nikad nije bilo ovako zabavno. Sa ultra jednostavnim interfejsom (da ne kažemo - lakše nego skrolovanje mreža) i gomilom dizajnerskih fora, naša aplikacija pretvara svaku ideju u remek-delo.',
	'Your ideal present is just a few clicks away.': 'Personalizacija na max – sve ti je pod prstima!',
	'Create personalized products in minutes with our easy-to-use app!': 'Pusti mašti na volju i skockaj personalizovane poklone online i u realnom vremenu vidi kako izgledaju.',
	'BRING YOUR GIFTS TO LIFE': 'OŽIVITE SVOJE POKLONE',
	'Perfect gift for every occasion': 'Savršen poklon za svaku priliku',
	'Discover the ultimate gift for every occasion – the perfect present awaits you!':
		'Istraži našu kolekciju i napravi svoj personalizovani poklon. Bilo da je rođendan, godišnjica ili samo želiš da pokažeš da ti je stalo, kreiraj poklon koji je baš kao ti – savršen i potpuno tvoj. Oduševi sve u par klikova!',
	"Whether you're celebrating birthdays, anniversaries, or simply want to show someone you care, our collection ensures that you'll find the ideal gift to match the mood and sentiment of the occasion.": '',
	'Unique design': 'Jedinstven dizajn',
	'Fast delivery': 'Brza dostava',
	'Easy shopping': 'Lako naručivanje',
	'Easy ordering': 'Jednostavno naručivanje',
	'You ask us this most often': 'Najčešća pitanja koja postavljate',
	"Got a burning question? Don't worry, we're not just here for the gifts, but for the answers too! Here, we dive into the most frequently asked questions and clear up any doubts you might have – so your curiosity is fully satisfied!":
		'Zanima te nešto? Ne brini, nismo mi zaduženi samo za poklone, već i za odgovore! Ovdje istražujemo najčešće postavljana pitanja i razjasnićemo svaku nedoumicu – da tvoja radoznalost bude potpuno zadovoljena!',
	'Back to login': 'Nazad na prijavu',
	'Check you email!': 'Proverite svoj email!',
	'Create account': 'Kreiraj nalog',
	'Don’t have an account?': 'Nemate nalog?',
	'Email is not valid!': 'Email nije validan!',
	'Enter your email below to receive your password reset instructions': 'Unesite svoj email ispod kako biste dobili uputstva za resetovanje lozinke',
	'Field is required!': 'Polje je obavezno!',
	'Forgot password?': 'Zaboravljena lozinka?',
	'Forgot your Password?': 'Zaboravili ste lozinku?',
	'Invalid email address': 'Nevažeća email adresa',
	'Log in': 'Prijavi se',
	'My draft': 'Moj nacrt',
	Profile: 'Profil',
	Resend: 'Ponovo pošalji',
	Send: 'Pošalji',
	'Sign in': 'Prijavi se',
	'We have sent a password reset instruction to your email': 'Poslali smo uputstva za resetovanje lozinke na vaš email',
	required: 'obavezno',
	'Already have an account?': 'Već imate nalog?',
	'Confirm password *': 'Potvrdite lozinku *',
	'Email *': 'Email *',
	'First name *': 'Ime *',
	'Last name *': 'Prezime *',
	'Lozinke se ne slažu molim Vas proverite.': 'Lozinke se ne poklapaju, molimo vas proverite.',
	'Password *': 'Lozinka *',
	'Poslat vam je email za verifikaciju naloga. Molimo vas proverite vas inbox (pogledajte spam ili junk folder ukoliko nije u inboxu).': 'Poslat vam je email za verifikaciju naloga. Molimo vas proverite svoj inbox (pogledajte spam ili junk folder ako nije u inboxu).',
	'Sign Up': 'Registracija',
	'Šifra treba da ima najmanje 3 karaktera!': 'Lozinka mora imati najmanje 3 karaktera!',
	'Šifra treba da ima najmanje 8 karaktera!': 'Lozinka mora imati najmanje 8 karaktera!',
	'Šifra treba da ima najmanje 8 karaktera, jedno veliko slovo i jedan broj': 'Lozinka mora imati najmanje 8 karaktera, jedno veliko slovo i jedan broj',
	Login: 'Prijava',
	'Change password': 'Promeni lozinku',
	'Password Change': 'Promena lozinke',
	'Please, enter a new password below.': 'Molimo, unesite novu lozinku ispod.',
	'Reset password': 'Resetuj lozinku',
	'Take me to layka': 'Odvedi me na Layka',
	'Well done! You have successfully changed your password!': 'Bravo! Uspešno ste promenili lozinku!',
	ABOUT: 'O NAMA',
	'Design by': 'Dizajnirao',
	FAQ: 'Česta pitanja',
	Kontakt: 'Kontakt',
	'O nama': 'O nama',
	'Plaćanje i isporuka': 'Plaćanje i isporuka',
	'Politika Privatnosti': 'Politika privatnosti',
	'Povraćaj sredstava': 'Povraćaj sredstava',
	Products: 'Proizvodi',
	'SECURE PAYMENT': 'SIGURNO PLAĆANJE',
	'Uslovi korišćenja': 'Uslovi korišćenja',
	HOME: 'POČETNA',
	Home: 'Početna',
	'Choose Options': 'Izaberite opcije',
	'Processing & Delivering:': 'Obrada i isporuka:',
	SHIPPING: 'DOSTAVA',
	'Sold out': 'Rasprodato',
	'Technical specs': 'Tehničke specifikacije',
	'personalize this product': 'personalizujte ovaj proizvod',
	'Add Photo': 'Dodaj fotografiju',
	'Add symbol': 'Dodaj simbol',
	'Add text': 'Dodaj tekst',
	Back: 'Nazad',
	'Choose design': 'Izaberite dizajn',
	'Font Size': 'Veličina fonta',
	'Make your own': 'Napravite svoj dizajn',
	'Press to add image': 'Pritisnite da dodate sliku',
	'TEXT ALIGNMENT': 'PORAVNANJE TEKSTA',
	'TEXT COLOR': 'BOJA TEKSTA',
	'style your text': 'stilizujte svoj tekst',
	'Are you sure you want to delete your design?': 'Da li ste sigurni da želite da obrišete svoj dizajn?',
	'Delete design': 'Obriši dizajn',
	'Your design progress will be lost.': 'Vaš napredak u dizajnu će biti izgubljen.',
	'Your design progress will be lost. Return to save before you start over.': 'Vaš napredak u dizajnu će biti izgubljen. Vratite se da sačuvate pre nego što počnete ispočetka.',
	'add new text box': 'dodajte novi tekstualni okvir',
	'save your design': 'sačuvajte svoj dizajn',
	'Edit text': 'Uredi tekst',
	Font: 'Font',
	'Text color': 'Boja teksta',
	'style your design': 'stilizujte svoj dizajn',
	'ADD TO CART': 'DODAJ U KORPU',
	'UPDATE CART': 'AŽURIRAJ KORPU',
	'Wrap your gift': 'Upakujte svoj poklon',
	'Explore more products': 'Istražite više proizvoda',
	'New Text': 'Novi tekst',
	'Product added to cart successfully!': 'Proizvod je uspešno dodat u korpu!',
	'go to checkout': 'idite na naplatu',
	'Browse our products to start filling it now.': 'Pregledajte naše proizvode da biste počeli da ih dodajete.',
	'COMPLETE YOUR PURCHASE': 'ZAVRŠITE SVOJU KUPOVINU',
	Cart: 'Korpa',
	Checkout: 'Naplata',
	Delivery: 'Dostava',
	'Enter note here': 'Unesite napomenu ovde',
	'Explore products': 'Istražite proizvode',
	'Free delivery': 'Besplatna dostava',
	'GO TO CHECKOUT': 'IDITE NA NAPLATU',
	'Incl.Tax': 'Uključujući porez',
	Items: 'Proizvodi',
	Note: 'Napomena',
	Subtotal: 'Međuzbir',
	'There is still space in your cart': 'Još uvek ima mesta u vašoj korpi',
	Total: 'Ukupno',
	'see at the checkout': 'pogledajte na naplati',
	Edit: 'Uredi',
	Qty: 'Količina',
	'*Invalid email address': '*Nevažeća email adresa',
	'*Invalid phone number': '*Nevažeći broj telefona',
	'*This field is required': '*Ovo polje je obavezno',
	'Customer details': 'Podaci o kupcu',
	'Invalid email format': 'Nevažeći format email adrese',
	'Invalid phone number format': 'Nevažeći format broja telefona',
	'Select country *': 'Izaberite zemlju *',
	'Shipping Address': 'Adresa za dostavu',
	'Address *': 'Adresa *',
	'City *': 'Grad *',
	Logout: 'Odjava',
	'Phone number *': 'Broj telefona *',
	'Zip code *': 'Poštanski broj *',
	Add: 'Dodaj',
	'Address:': 'Adresa:',
	'City:': 'Grad:',
	'Email:': 'Email:',
	'Full Name:': 'Puno ime:',
	'No default address found': 'Nema podrazumevane adrese',
	'Payment Method': 'Metod plačanja',
	'Phone number:': 'Broj telefona:',
	'Shipping Method': 'Metod dostave',
	'State:': 'Država:',
	'Zip code:': 'Poštanski broj:',
	'Thank you for': 'Hvala što ste',
	'your order!': 'poručili vašu narudžbinu!',
	'Your order has been successfully submitted!': 'Vaša narudžbina je uspešno napravljena!',
};
export default TRANSLATION_SR;
