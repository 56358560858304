import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import { ReactComponent as Logo } from '../assets/logo.svg';

function LoadingScreen({ visible }: { visible: boolean }) {
	const [hide, setHide] = useState(false);
	const MemoizedLogo = React.memo(Logo);
	useEffect(() => {
		if (!visible && !hide) {
			setTimeout(() => setHide(true), 230);
		}
	}, [visible, hide]);

	return (
		<div className={`absolute h-[100dvh] w-full z-10 bg-darkBlue xs:bg-[#ffffff] flex items-center justify-center flex-col gap-[60px] pb-[260px] transition-all ease-in-out duration-[230ms] ${visible ? 'opacity-100 visible' : 'opacity-0 invisible'} ${hide ? 'hidden' : ''}`}>
			<MemoizedLogo width={300} height={200} />
			<Loading />
		</div>
	);
}

export default LoadingScreen;
