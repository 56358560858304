import React from 'react';

function AppStoreButtons() {
	return (
		<>
			<a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer" title="App Store">
				<img src={require('../assets/images/appstore-logo.webp')} loading="lazy" alt="App Store" height="40" width={130} title="App Store" />
			</a>
			<a href="https://play.google.com/store/apps/details?id=com.laykagift.laykaApp&utm_source=website" target="_blank" rel="noreferrer" title="Play Store">
				<img src={require('../assets/images/playstore-logo.webp')} loading="lazy" alt="Play Store" height="40" width={130} title="Play Store" />
			</a>
		</>
	);
}

export default AppStoreButtons;
