import React from 'react';
import SEO from '../seo/seo';
import { getImage } from '../../server/server';
import Loading from '../../common/Loading';

function ProductNameAndPrice({
	name = '',
	price = '',
	currency = '',
	product,
	orderProgress = false,
	editCartMode = false,
	addItemToCart = () => {},
	disabledButton = false,
	designPrice = 0,
}: {
	name: string;
	price: string;
	currency: string;
	product: any;
	orderProgress?: boolean;
	editCartMode?: boolean;
	addItemToCart?: () => void;
	disabledButton?: boolean;
	designPrice?: number;
}) {
	const seo = product.seo;
	const image = !!product?.files?.length ? product?.files[0] : null;

	return (
		<div className="flex flex-col gap-[10px]">
			{/* <div className="flex items-end justify-end mb-1">
				<button className={`rounded-full px-[62px] text-[white] h-[44px] text-bold min-w-[285px] items-center justify-center flex ${disabledButton ? 'bg-[#DFDFDF]' : 'bg-[#21187f]'}`} onClick={addItemToCart} disabled={disabledButton}>
					{orderProgress ? <Loading height={24} color="#0F2765" /> : <p>{editCartMode ? 'UPDATE CART' : `ADD TO CART (${designPrice + parseFloat(product?.commercialOptions?.price)}${product?.zone?.currency?.symbol?.toLowerCase()})`}</p>}
				</button>
			</div> */}
			<SEO title={seo?.metaTitle} description={seo?.metaDescription} keywords={seo?.metaKeywords} price={price} currency={currency} imageUrl={image ? getImage(image?.file?.id) : ''} imageAlt={seo?.metaTitle} urlType="product" />
			<h1 className="text-[28px] font-[400]">{name}</h1>
			<div className="flex flex-row justify-between items-center">
				<p className="text-[20px] font-normal -mt-[6px] text-[#21187F]">
					{parseInt(price) < 0 ? '' : price} <span className="lowercase">{currency}</span>
				</p>
			</div>
		</div>
	);
}

export default ProductNameAndPrice;
