import React, { useMemo, useState } from 'react';
import { ReactComponent as PurpleTimes } from '../../../assets/icons/PurpleTimes.svg';
import Loading from '../../../common/Loading';
import { fabric } from 'fabric';
type Type = {
	obj: any;
	canvas?: fabric.Canvas;
	loading?: boolean;
};

function ShowImagePreview({ obj, canvas, loading = false }: Type) {
	const imageUrl = useMemo(() => (loading ? null : (obj as any).getElement()?.src), [obj, loading]);

	const deleteObj = () => {
		const rootObj = { ...obj.rootObj };
		rootObj.set({ fill: 'transparent' });
		// fabric?.util?.enlivenObjects(
		// 	[rootObj],
		// 	([rootObj]: any) => {
		const createObjFunction = obj.createObjFunction;
		const newRootObj = { ...rootObj };
		const oldRootElement = obj.createObjFunction(rootObj);
		(oldRootElement as any).rootObj = newRootObj;
		(oldRootElement as any).createObjFunction = createObjFunction;
		const objects = canvas?.getObjects();
		const frameObject = objects?.find((o: any) => obj.frameId === o.id);
		canvas?.remove(obj);
		if (!!frameObject) canvas?.remove(frameObject);
		canvas?.add(oldRootElement);
		canvas?.requestRenderAll();
		// 	},
		// 	''
		// );
	};

	return (
		<div className="rounded-[10px] bg-[#F1F1FEB3] w-[92px] h-[92px] flex items-center justify-center relative overflow-hidden">
			{loading ? (
				<Loading height={30} />
			) : (
				<>
					<button className="top-[5px] right-[5px] w-[14px] h-[15px] rounded-full bg-[#F1F1FE] flex items-center justify-center absolute" onClick={deleteObj}>
						<PurpleTimes />
					</button>
					<img src={imageUrl} width={90} height={90} alt="" />
				</>
			)}
		</div>
	);
}

export default ShowImagePreview;
