import React, { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import NavBar from '../NavBar';
import Footer from '../Footer';
import CountryScreen from './CountryScreen';
import { useAppContext } from '../../hooks/useAppContext';
import MobileScreen from '../../MobileScreen';

function Layout() {
	const { zone } = useAppContext();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkIfMobile = () => {
			const mobileWidth = 600;
			console.log('window.innerWidth', window.innerWidth);

			setIsMobile(window.innerWidth < mobileWidth);
		};

		checkIfMobile();

		window.addEventListener('resize', checkIfMobile);

		return () => {
			window.removeEventListener('resize', checkIfMobile);
		};
	}, []);

	if (isMobile) return <MobileScreen />;

	return (
		<div className="home h-screen overflow-y-auto flex flex-col" id="scroll-div">
			<div className="home-container w-full flex-grow flex flex-col">
				<NavBar />
				<div className=" outlet-container ">
					<div className="w-full overflow-y-hidden flex-grow">
						<Outlet />
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Layout;
