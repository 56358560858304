import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/fonts.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppProviders from './components/AppProvider';
import FontLoader from './components/FontLoader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.error = () => {};
	console.warn = () => {};
	// Optional: also silence other console methods
	console.info = () => {};
	console.debug = () => {};
}

root.render(
	<React.StrictMode>
		<AppProviders>
			<BrowserRouter>
				<App />
				<FontLoader />
			</BrowserRouter>
		</AppProviders>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
