import { fabric } from 'fabric';
import { DEFAULT_TEXT_CONFIG, HideControls } from '../Constants';

export const configureDesignLabel = (obj: any, editable: boolean, partiallyEditable: boolean, allowedFonts: any[], canvas?: fabric.Canvas, scaleFactor: number = 1) => {
	(obj as any).allowedFonts = allowedFonts ?? [];
	if (editable || partiallyEditable) {
		// const objWidth = (obj?.width ?? 0) * (obj.scaleX ?? 0) + 2; // Sluzi da bi se okvir poklopio sa ivicom teksta
		// const objHeight = (obj?.height ?? 0) * (obj.scaleY ?? 0) + 2; // Sluzi da bi se okvir poklopio sa ivicom teksta
		const objWidth = obj.maxWidth;
		const objHeight = obj.maxHeight;
		(obj as any).set({
			selectable: true,
			evented: true,
			hoverCursor: 'pointer',
			hasBorders: false,
			id: Date.now(),
			splitByGrapheme: true,
			// width: objWidth / scaleFactor,
			// height: objHeight,
		});
		(obj as any).maxHeight = objHeight;
		(obj as any).maxWidth = objWidth;
		(obj as any).maxFontSize = obj.fontSize;

		(obj as any).textBoxEditable = !!editable;
		(obj as any).partiallyEditable = !!partiallyEditable;

		obj.setControlsVisibility(HideControls);
		const rectangleAddition = new fabric.Rect({
			top: (obj.top ?? 0) - 1, // Sluzi da bi se okvir poklopio sa ivicom teksta
			left: (obj.left ?? 0) - 1, // Sluzi da bi se okvir poklopio sa ivicom teksta
			height: objHeight,
			width: objWidth,
			// stroke: '#E5DDF6',
			stroke: '#21187F',
			strokeDashArray: [4, 4],
			strokeWidth: 1,
			selectable: false,
			evented: false,
			hoverCursor: 'default',
			name: 'remove',
			fill: '#F89DBD80',
			angle: obj.angle,
		});
		(rectangleAddition as any).skip = true;
		(rectangleAddition as any).textId = obj.id;
		canvas?.add(rectangleAddition);
	} else {
		(obj as any).set({
			selectable: false,
			evented: false,
			hoverCursor: 'default',
			hasBorders: false,
			editable: false,
		});
	}
	return obj;
};

export const createGroupObj = (obj: fabric.Object, onSelect = (e: any) => {}, t = (p0: string): any => {}) => {
	const objWidth = (obj?.width ?? 0) * (obj.scaleX ?? 0);
	const objHeight = (obj?.height ?? 0) * (obj.scaleY ?? 0);

	obj.set({
		name: 'croppingArea',
		selectable: true,
		evented: true,
		hoverCursor: 'pointer',
		stroke: '#D4F158',
		strokeWidth: 1 / ((obj as any).scaleX ?? 1),
		angle: obj.angle,
	});

	obj.setControlsVisibility(HideControls);
	obj.setControlVisible('deleteControl', false);

	const textComponent = new fabric.Textbox(t('Press to add image'), {
		left: obj.left,
		width: objWidth,
		height: objHeight,
		fontFamily: 'Noto Sans',
		top: (obj?.top ?? 0) + objHeight / 2,
		...DEFAULT_TEXT_CONFIG,
		fontSize: 20,
		fill: '#D4F158',
		textAlign: 'center',
		splitByGrapheme: false,
		originY: 'center',
		angle: obj.angle,
	});

	(textComponent as any).skip = true;

	obj = new fabric.Group([obj, textComponent], {
		// left: obj.left,
		// top: obj.top,
		lockMovementX: true,
		lockMovementY: true,
		selectable: true,
		evented: true,
		hoverCursor: 'pointer',
		hasControls: false,
		angle: 0,
	});

	obj.setControlsVisibility(HideControls);
	obj.setControlVisible('deleteControl', false);
	obj.name = 'group';
	obj.on('selected', onSelect);
	return obj;
};

// () => {
//     if (!!fileInputRef.current) fileInputRef.current.value = '';
//     selectedObjectInDesign.current = obj;
//     fileInputRef.current?.click();
// }

export const configureCroppingArea = (obj: any, onSelectArea: any, t: any) => {
	const rootObj = { ...obj };
	(obj as any).set({ fill: 'transparent', stroke: '#D4F158', strokeWidth: 20 });
	obj = createGroupObj(obj, onSelectArea, t);
	(obj as any).rootObj = rootObj;
	(obj as any).createObjFunction = (obj: any) => createGroupObj(obj, onSelectArea, t);
	return obj;
};

export const configureInitDesignItem = (item: any, scaleFactor: number, cart = false) => {
	let obj = new fabric.Object(item);
	(obj as any).crossOrigin = 'Anonymous';
	(obj as any).set({
		scaleX: (obj.scaleX || 1) * scaleFactor,
		scaleY: (obj.scaleY || 1) * scaleFactor,
		left: (obj.left || 1) * scaleFactor,
		top: (obj.top || 1) * scaleFactor,
		fontSize: ((obj as any)?.fontSize || 1) * scaleFactor,
		lockMovementX: true,
		lockMovementY: true,
		selectable: false,
		evented: false,
	});

	(obj as any).set({ id: Date.now() + Math.random() });

	const objWidth = (obj?.width ?? 0) * (obj.scaleX ?? 0);
	const objHeight = (obj?.height ?? 0) * (obj.scaleY ?? 0);
	(obj as any).maxHeight = objHeight;
	(obj as any).maxWidth = objWidth;
	(obj as any).initTop = obj.top;
	(obj as any).initLeft = obj.left;
	if (!!item.printingMethod) {
		(obj as any).printingMethod = item.printingMethod;
	}
	if (!!obj.clipPath && !cart) {
		obj.clipPath = new fabric.Object(obj.clipPath);
		obj.clipPath.set({
			left: (obj.clipPath.left || 1) * scaleFactor,
			top: (obj.clipPath.top || 1) * scaleFactor,
			scaleX: (obj.clipPath.scaleX || 1) * scaleFactor,
			scaleY: (obj.clipPath.scaleY || 1) * scaleFactor,
		});

		(obj as any).set({
			clipPath: {
				...obj.clipPath,
				scaleX: (obj.scaleX || 1) * scaleFactor,
				scaleY: (obj.scaleY || 1) * scaleFactor,
				left: (obj.left || 1) * scaleFactor,
				top: (obj.top || 1) * scaleFactor,
			},
		});
	}
	return obj;
};
