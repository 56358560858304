const TRANSLATION_EN = {
	'Create your products with our application': 'Create your products with our application.',
	"Dive into a sea of options and whip up a gift that's cool, original, and totally your vibe. Forget boring, generic gifts – now you're the gift design boss! Finding the perfect present has never been this fun. With a super easy-to-use interface (seriously, easier than scrolling your feed) and tons of design tricks up its sleeve, our app turns every idea into a masterpiece. Maximum personalization – all at your fingertips!":
		"Dive into a sea of options and whip up a gift that's cool, original, and totally your vibe. Forget boring, generic gifts – now you're the gift design boss! Finding the perfect present has never been this fun. With a super easy-to-use interface (seriously, easier than scrolling your feed) and tons of design tricks up its sleeve, our app turns every idea into a masterpiece. Maximum personalization – all at your fingertips!",
	'download the app': 'download the app',
	'Trending now': 'Trending now',
	'Create your products with our application.': 'Create your products with our application.',
	'Dive into the realm of creativity with our innovative application that puts the power of design into your hands! Unleash your imagination and craft personalized gifts that reflect your unique style.':
		'Dive into the realm of creativity with our innovative application that puts the power of design into your hands! Unleash your imagination and craft personalized gifts that reflect your unique style.',
	footer_banner_last_section:
		'Explore countless possibilities as you embark on a journey to create gifts that are cool and individual, just like you. Finding the perfect gift has never been this exciting - dive into a world of endless options and bring joy to every special occasion! With an intuitive interface and a multitude of design options, our app transforms ordinary presents into extraordinary personalized gifts - all at your fingertips',
	'Your ideal present is just a few clicks away.': 'Your ideal present is just a few clicks away.',
	'Create personalized products in minutes with our easy-to-use app!': 'Create personalized products in minutes with our easy-to-use app!',
	'BRING YOUR GIFTS TO LIFE': 'BRING YOUR GIFTS TO LIFE',
	'Perfect gift for every occasion': 'Perfect gift for every occasion',
	'Discover the ultimate gift for every occasion – the perfect present awaits you!': 'Discover the ultimate gift for every occasion – the perfect present awaits you!',
	"Whether you're celebrating birthdays, anniversaries, or simply want to show someone you care, our collection ensures that you'll find the ideal gift to match the mood and sentiment of the occasion.":
		"Whether you're celebrating birthdays, anniversaries, or simply want to show someone you care, our collection ensures that you'll find the ideal gift to match the mood and sentiment of the occasion.",
	'Unique design': 'Unique design',
	'Fast delivery': 'Fast delivery',
	'Easy shopping': 'Easy shopping',
	'Easy ordering': 'Easy ordering',
	'You ask us this most often': 'You ask us this most often',
	"Got a burning question? Don't worry, we're not just here for the gifts, but for the answers too! Here, we dive into the most frequently asked questions and clear up any doubts you might have – so your curiosity is fully satisfied!":
		"Got a burning question? Don't worry, we're not just here for the gifts, but for the answers too! Here, we dive into the most frequently asked questions and clear up any doubts you might have – so your curiosity is fully satisfied!",

	'Back to login': 'Back to login',
	'Check you email!': 'Check you email!',
	'Create account': 'Create account',
	'Don’t have an account?': 'Don’t have an account?',
	'Email is not valid!': 'Email is not valid!',
	'Enter your email below to receive your password reset instructions': 'Enter your email below to receive your password reset instructions',
	'Field is required!': 'Field is required!',
	'Forgot password?': 'Forgot password?',
	'Forgot your Password?': 'Forgot your Password?',
	'Invalid email address': 'Invalid email address',
	'Log in': 'Log in',
	'My draft': 'My draft',
	Profile: 'Profile',
	Resend: 'Resend',
	Send: 'Send',
	'Sign in': 'Sign in',
	'We have sent a password reset instruction to your email': 'We have sent a password reset instruction to your email',
	required: 'required',
	'*Invalid email address': '*Invalid email address',
	'*Invalid phone number': '*Invalid phone number',
	'*This field is required': '*This field is required',
	ABOUT: 'ABOUT',
	'ADD TO CART': 'ADD TO CART',
	Add: 'Add',
	'Add Photo': 'Add Photo',
	'Add symbol': 'Add symbol',
	'Add text': 'Add text',
	'Address *': 'Address *',
	'Address:': 'Address:',
	'Already have an account?': 'Already have an account?',
	'Are you sure you want to delete your design?': 'Are you sure you want to delete your design?',
	Back: 'Back',
	'Browse our products to start filling it now.': 'Browse our products to start filling it now.',
	'COMPLETE YOUR PURCHASE': 'COMPLETE YOUR PURCHASE',
	Cart: 'Cart',
	'Change password': 'Change password',
	Checkout: 'Checkout',
	'Choose Options': 'Choose Options',
	'Choose design': 'Choose design',
	'City *': 'City *',
	'City:': 'City:',
	'Confirm password *': 'Confirm password *',
	'Customer details': 'Customer details',
	'Delete design': 'Delete design',
	Delivery: 'Delivery',
	'Design by': 'Design by',
	Edit: 'Edit',
	'Edit text': 'Edit text',
	'Email *': 'Email *',
	'Email:': 'Email:',
	'Enter note here': 'Enter note here',
	'Explore more products': 'Explore more products',
	'Explore products': 'Explore products',
	FAQ: 'FAQ',
	'First name *': 'First name *',
	Font: 'Font',
	'Font Size': 'Font Size',
	'Free delivery': 'Free delivery',
	'Full Name:': 'Full Name:',
	'GO TO CHECKOUT': 'GO TO CHECKOUT',
	Home: 'Home',
	'Incl.Tax': 'Incl.Tax',
	'Invalid email format': 'Invalid email format',
	'Invalid phone number format': 'Invalid phone number format',
	Items: 'Items',
	Kontakt: 'Kontakt',
	'Last name *': 'Last name *',
	Login: 'Login',
	Logout: 'Logout',
	'Lozinke se ne slažu molim Vas proverite.': 'Lozinke se ne slažu molim Vas proverite.',
	'Make your own': 'Make your own',
	'New Text': 'New Text',
	'No default address found': 'No default address found',
	Note: 'Note',
	'O nama': 'O nama',
	'Password *': 'Password *',
	'Password Change': 'Password Change',
	'Payment Method': 'Payment Method',
	'Phone number *': 'Phone number *',
	'Phone number:': 'Phone number:',
	'Plaćanje i isporuka': 'Plaćanje i isporuka',
	'Please, enter a new password below.': 'Please, enter a new password below.',
	'Politika Privatnosti': 'Politika Privatnosti',
	'Poslat vam je email za verifikaciju naloga. Molimo vas proverite vas inbox (pogledajte spam ili junk folder ukoliko nije u inboxu).': 'Poslat vam je email za verifikaciju naloga. Molimo vas proverite vas inbox (pogledajte spam ili junk folder ukoliko nije u inboxu).',
	'Povraćaj sredstava': 'Povraćaj sredstava',
	'Press to add image': 'Press to add image',
	'Processing & Delivering:': 'Processing & Delivering:',
	'Product added to cart successfully!': 'Product added to cart successfully!',
	Products: 'Products',
	Qty: 'Qty',
	'Reset password': 'Reset password',
	'SECURE PAYMENT': 'SECURE PAYMENT',
	SHIPPING: 'SHIPPING',
	'Select country *': 'Select country *',
	'Shipping Address': 'Shipping Address',
	'Shipping Method': 'Shipping Method',
	'Sign Up': 'Sign Up',
	'Sold out': 'Sold out',
	'State:': 'State:',
	Subtotal: 'Subtotal',
	'TEXT ALIGNMENT': 'TEXT ALIGNMENT',
	'TEXT COLOR': 'TEXT COLOR',
	'Take me to layka': 'Take me to layka',
	'Technical specs': 'Technical specs',
	'Text color': 'Text color',
	'Thank you for': 'Thank you for',
	'There is still space in your cart': 'There is still space in your cart',
	Total: 'Total',
	'UPDATE CART': 'UPDATE CART',
	'Uslovi korišćenja': 'Uslovi korišćenja',
	'Well done! You have successfully changed your password!': 'Well done! You have successfully changed your password!',
	'Wrap your gift': 'Wrap your gift',
	'Your design progress will be lost.': 'Your design progress will be lost.',
	'Your design progress will be lost. Return to save before you start over.': 'Your design progress will be lost. Return to save before you start over.',
	'Your order has been successfully submitted!': 'Your order has been successfully submitted!',
	'Zip code *': 'Zip code *',
	'Zip code:': 'Zip code:',
	'add new text box': 'add new text box',
	'go to checkout': 'go to checkout',
	'personalize this product': 'personalize this product',
	'save your design': 'save your design',
	'see at the checkout': 'see at the checkout',
	'style your design': 'style your design',
	'style your text': 'style your text',
	'your order!': 'your order!',
	'Šifra treba da ima najmanje 3 karaktera!': 'Šifra treba da ima najmanje 3 karaktera!',
	'Šifra treba da ima najmanje 8 karaktera!': 'Šifra treba da ima najmanje 8 karaktera!',
	'Šifra treba da ima najmanje 8 karaktera, jedno veliko slovo i jedan broj': 'Šifra treba da ima najmanje 8 karaktera, jedno veliko slovo i jedan broj',
};
export default TRANSLATION_EN;
