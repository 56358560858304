import React from 'react';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import { useTranslation } from 'react-i18next';

type Type = {
	design?: any;
	removeObject?: (data: any) => void;
};

function EditDesignHeader({ design, removeObject = () => {} }: Type) {
	const { t } = useTranslation();
	return (
		<div className="flex flex-row justify-between justify-centerspo mt-[15px] pb-[15px] font-bold border-b border-b-[#D4C4F6]">
			<label htmlFor="style your text" className="uppercase text-[14px]  leading-[25px]">
				{t('style your design')} - <span className="font-normal">{design.designName}</span>
			</label>
			<button onClick={() => removeObject(design)} className="px-[15px]">
				<Trash />
			</button>
		</div>
	);
}

export default EditDesignHeader;
