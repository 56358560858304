import React, { useMemo } from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { ReactComponent as GiftBoxIcon } from '../../assets/icons/GiftBox.svg';
import { useTranslation } from 'react-i18next';

function GiftBoxButton({ giftAdded = false, onPress = () => {} }: { giftAdded?: boolean; onPress?: () => void }) {
	const { t } = useTranslation();
	const { zone } = useAppContext();

	const price = useMemo(() => zone.giftPrice.toFixed(2), [zone]);
	const currency = useMemo(() => zone?.currency?.symbol?.toLowerCase(), [zone]);

	return (
		<div className="flex items-end justify-end mb-1 pr-[25px]">
			<button onClick={onPress} className="flex flex-row items-center gap-[6px] px-[14px] border border-dashed border-[#D0BBEC] rounded-[12px] min-h-[33px] py-[4px]">
				<div className="flex items-center justify-center w-[16px] h-[16px] rounded-full border border-[#21187F]">{giftAdded ? <div className="w-[8px] bg-pink h-[8px] rounded-full" /> : null}</div>
				<p className="text-[12px]">
					{t('Wrap your gift')} - +{price} {currency}
				</p>
				<GiftBoxIcon />
			</button>
		</div>
	);
}

export default GiftBoxButton;
