import { useEffect, useRef, useState } from 'react';
import Loading from '../../common/Loading';
import { getAllowedDesigns, getImage, getProductDesign } from '../../server/server';
import { fabric } from 'fabric';
import WriteSomethingSection from './WriteSomethingSection';
import EditDesign from './Design/EditDesign';
import { useAppContext } from '../../hooks/useAppContext';

type Type = {
	setDisabledButton: (value: boolean) => void;
	productId?: string;
	selectDesign?: ({ design }: { design: any }) => void;
	chosenDesign: any;
	canvas?: any;
	removeDesign: () => void;
	engravingColor: string | null;
};

function ChooseDesignSection({ canvas = {}, productId = '', selectDesign = ({ design }: { design: any }) => {}, chosenDesign, removeDesign, setDisabledButton, engravingColor }: Type) {
	const [allowedDesigns, setAllowedDesigns] = useState([]);
	const [setObjects] = useState<any>([]);

	useEffect(() => {
		loadDesigns();
		if (!!canvas.id) {
			canvas?.on('object:added', detectChange);
			canvas?.on('object:removed', detectChange);
			canvas?.on('object:modified', detectChange);
		}
	}, []);

	const detectChange = () => {
		setObjects((canvas as any).getObjects());
	};

	const loadDesigns = async () => {
		const resp = await getAllowedDesigns(productId);
		if (resp.status === 200) {
			setAllowedDesigns(resp.data);
		}
	};

	return (
		<div className="">
			{!chosenDesign && (
				<>
					<div className="pb-[40px] pt-[35px]">
						<div className=" grid grid-cols-3 xl:gap-[30px] gap-[20px]  pb-[50px] max-w-[415px] xl:max-w-full ">
							{allowedDesigns.map((design: any) => (
								<DesignButton key={design.id} design={design} selectDesign={selectDesign} />
							))}
						</div>
					</div>
				</>
			)}
			{!!chosenDesign && <EditDesign design={chosenDesign} canvas={canvas} removeDesign={removeDesign} setDisabledButton={setDisabledButton} engravingColor={engravingColor} />}
		</div>
	);
}

export const EditTextSection = ({ obj, canvas, productId, chosenDesign, layer }: { obj: fabric.Object; canvas: fabric.Canvas; productId: string; chosenDesign: any; layer: any }) => {
	const renderAll = () => canvas.requestRenderAll();
	const [align, setAlign] = useState((obj as any).textAlign ?? 'center');

	const alignText = (align: string) => {
		(obj as fabric.Textbox).set({ textAlign: align });
		setAlign(align);
		renderAll();
	};

	const partiallyEnabled = (layer as any).partiallyEditable;

	return (
		<div className="-mb-[40px] pb-[40px]">
			<WriteSomethingSection loading={false} onAddText={() => {}} selectedObject={obj} renderAll={renderAll} productId={productId} alignText={alignText} alignedText={align} title="Edit text:" design partiallyEnabled />
		</div>
	);
};
export const AddImageSection = ({ obj, canvas }: { obj: fabric.Object; canvas: fabric.Canvas }) => {
	const [imageSelected, setImageSelected] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [imageSrc, setImageSrc] = useState('');

	useEffect(() => {
		setImageSelected(obj.type == 'image');
		if (obj.type == 'image') setImageSrc((obj as any).getElement()?.src);
	}, []);

	return (
		<div className="mb-[20px]">
			<div className="text-[16px] font-semibold mt-[20px]">Add Image:</div>
			{imageSelected && (
				<div className="mt-[14px] relative">
					<img src={imageSrc} width={140} height={140} className="rounded-[10px]" alt="" />
				</div>
			)}
			{!imageSelected && (
				<div className="mt-[14px]">
					<button className="gap-[10px] h-[44px] rounded-full px-[60px] bg-[#21187F] flex flex-row items-center justify-center" onClick={() => canvas.setActiveObject(obj)}>
						<span className="text-[16px] text-[white] uppercase font-bold">upload image</span>
					</button>
				</div>
			)}
		</div>
	);
};

export const DesignButton = ({ design = {}, selectDesign = () => {} }: { design: any; selectDesign: ({ design }: { design: any }) => void }) => {
	const [loaded, setLoaded] = useState(false);
	const { zone } = useAppContext();
	const [loading, setLoading] = useState(false);
	const imageUrl = getImage(design.thumbnailId);

	const loadProductDesign = async () => {
		setLoading(true);

		const resp = await getProductDesign(design.id);
		if (resp.status == 200) {
			selectDesign({ design: resp.data });
			setLoading(false);
		}
	};

	console.log(zone);
	const currency = zone?.currency?.symbol?.toLowerCase();

	return (
		<button className={`transition-all w-full items-center flex flex-col duration-[230ms] bg-[#F1F1FEB3] border border-white hover:border-[#21187F] ${loading ? '' : ''} relative overflow-hidden rounded-[10px] `} onClick={loadProductDesign} disabled={loading}>
			<div className={`flex-1 flex flex-col items-center w-full px-[5px] pt-[5px]`}>
				<p className={`text-[18px] leading-[20px] ${!!design.price ? 'font-bold text-[#21187F]' : 'font-normal'} `}>{design.designName}</p>
				<p className="text-[11px] pb-[2px] text-[#21187F]">{!!design.price ? `+${design.price}${currency ?? 'rsd'}` : ''}</p>
			</div>
			<div className="w-full aspect-square">
				<img src={imageUrl} alt="Symbol" className="object-fill w-full" onLoad={() => setLoaded(true)} />
				{!loaded && (
					<div className="absolute top-[40px] bottom-0 left-0 right-0 flex items-center justify-center">
						<Loading height={40} />
					</div>
				)}
				{loading && (
					<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-[#21187F33]">
						<Loading height={40} />
					</div>
				)}
			</div>
		</button>
	);
};

export default ChooseDesignSection;
