import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

function ProductGroup({
	title = 'Choose your color:',
	selectedOption = [],
	allSelectedOptions = [],
	options = ['white', 'purple', 'pink', 'blue', 'orange'],
	isColor = false,
	product = {},
	standAlone = false,
}: {
	options?: any[];
	isColor?: boolean;
	title?: string;
	selectedOption: any;
	product: any;
	standAlone: boolean;
	allSelectedOptions: any[];
}) {
	const groupId = useMemo(() => {
		const optionsIds = options.map((e) => e.id);
		for (let i in allSelectedOptions) {
			const option = allSelectedOptions[i];
			if (optionsIds.includes(option)) {
				return parseInt(i);
			}
		}
		return 0;
	}, [allSelectedOptions, options]);

	return (
		<div>
			<div>
				<p className="text-[16px] font-medium">
					{title}
					{title.includes(':') ? '' : ':'}
				</p>
			</div>
			<div className="flex flex-row gap-[30px] mt-[14px]">
				{options.map((option, index) => (
					<OptionButton option={option} selected={selectedOption?.includes(option.id)} product={product} isColor={isColor} allSelectedOptions={allSelectedOptions} groupIndex={groupId} />
				))}
			</div>
		</div>
	);
}

const OptionButton = ({ option, selected = false, isColor = false, product = {}, allSelectedOptions = [], groupIndex }: { option: any; selected: boolean; isColor: boolean; product: any; allSelectedOptions: any[]; groupIndex: number }) => {
	const ifSelected = allSelectedOptions.map((currOption, optionIndex) => (optionIndex === groupIndex ? option.id : currOption)).join(',');

	return (
		<Link to={`/product/${product.urlSlug}--${ifSelected}`}>
			<div className="flex flex-col items-center cursor-pointer ">
				<div className={`min-w-[40px] h-[40px] flex items-center justify-center rounded-full px-[5px] ${selected ? 'border border-[#F89DBD]' : ''}`}>
					{!isColor && <div className="font-normal text-[14px] capitalize">{option.name}</div>}
					{isColor && <div style={{ backgroundColor: !!option?.color ? option?.color : '#C52A2A' }} className="border border-[#F1F1FE] rounded-full h-[30px] w-[30px]"></div>}
				</div>
				{isColor && <div className="mt-[8px] text-[14px] font-normal capitalize">{option.name}</div>}
			</div>
		</Link>
	);
};

export default ProductGroup;
