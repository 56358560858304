import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/pinkArrowIcon.svg';
import { getImage } from '../../server/server';
import { ReactComponent as PlayButton } from '../../assets/icons/PlayButton.svg';

const OptimizedImage = memo(({ src, isActive, alt = 'Design' }: { src: string; isActive: boolean; alt?: string }) => (
	<img
		src={src}
		alt={alt}
		loading={isActive ? 'eager' : 'lazy'}
		// fetchPriority={isActive ? 'high' : 'low'}

		style={{ opacity: isActive ? 1 : 0 }}
		className="transition-all ease-in-out absolute duration-500 object-contain w-full h-full"
		onError={(e) => {
			e.currentTarget.src = 'fallback-image-url.jpg';
		}}
	/>
));

function ImageSlider({ images = [] }: { images?: any[] }) {
	const [selectedImage, setSelectedImage] = useState(0);
	const onlyOneImage = images?.length <= 1;
	const goNext = () => {
		const nextIndex = selectedImage === images.length - 1 ? 0 : selectedImage + 1;
		setSelectedImage(nextIndex);
	};

	const goPrevious = () => {
		const nextIndex = selectedImage === 0 ? images.length - 1 : selectedImage - 1;
		setSelectedImage(nextIndex);
	};
	const firstImage = images[0];

	// {/* 738px */}
	return (
		// <div className="flex-1 w-full max-h-[588px] min-h-[588px] h-full relative flex">
		<div className="flex-1 w-full max-h-[760px] min-h-[760px] h-full relative flex">
			{firstImage?.file?.mimetype?.includes('image') && <img src={`${getImage(images[0]?.file?.id)}`} alt="Design" width={'100%'} className="w-full object-contain opacity-0 z-0" />}
			{images.map((image, index) => (image?.file?.mimetype?.includes('image') ? <OptimizedImage key={image.id || index} src={getImage(image?.file?.id)} isActive={selectedImage === index} /> : <SimpleVideoPlayer video={image.file} selected={selectedImage === index} />))}
			{!onlyOneImage && (
				<div className="w-[40px] h-[40px] rounded-full bg-[#FFFFFFB3] absolute left-[30px] top-[50%] -translate-y-[50%] cursor-pointer flex items-center justify-center z-[1]" onClick={goPrevious}>
					<Arrow />
				</div>
			)}
			{!onlyOneImage && (
				<div className="w-[40px] h-[40px] rounded-full bg-[#FFFFFFB3] absolute right-[30px] top-[50%] -translate-y-[50%] cursor-pointer flex items-center justify-center rotate-180 z-[1]" onClick={goNext}>
					<Arrow />
				</div>
			)}

			{!onlyOneImage && (
				<div className="absolute bottom-[30px] left-[50%] -translate-x-[50%] flex flex-row gap-[10px] z-[1]">
					{images.map((image, index) => (
						<div key={image.id} className={`cursor-pointer w-[8px] h-[8px] transition-all ease-in-out duration-500 rounded-full bg-white ${selectedImage !== index ? 'opacity-70' : ''}`} onClick={() => setSelectedImage(index)}></div>
					))}
				</div>
			)}
		</div>
	);
}

const SimpleVideoPlayer = ({ video, selected }: { video: any; selected: boolean }) => {
	const videoRef = useRef<any>(null);
	const [playing, setPlaying] = useState(true);
	useEffect(() => {
		if (!selected) stopVideo();
		else handlePlayVideo();
	}, [selected]);

	const handlePlayVideo = () => {
		videoRef?.current?.play();
		setPlaying(true);
	};

	const pauseVideo = () => {
		setPlaying(false);
		videoRef.current.controls = false;
	};

	const stopVideo = () => {
		setPlaying(false);
		videoRef.current.currentTime = 0;
		videoRef.current.pause();
	};
	return (
		<div className="min-w-full min-h-full absolute flex items-center justify-center" style={{ zIndex: selected ? 1 : 0 }}>
			<video key={video.id} style={{ pointerEvents: 'all', width: '100%', height: '100%', opacity: selected ? 1 : 0, objectFit: 'cover' }} ref={videoRef} onPause={pauseVideo} autoPlay>
				<source src={getImage(video.id)} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	);
};

export default ImageSlider;
